import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, UrlSegment } from '@angular/router';
import { AuthService } from '@services/auth/auth.service';

export const canMatch = (route: ActivatedRouteSnapshot, segments: UrlSegment[]): boolean => hasAppAccess(segments.map(segment => segment.path).join('/'));


const hasAppAccess = (url: string): boolean => {
    const auth = inject(AuthService);
    const isLoggedIn = auth.isLoggedIn();
    if (!isLoggedIn) {
        auth.beginLogin(url);
        return false;
    }
    return true;
};
