<app-dialog-container [title]="'Select Recipients'" [loading]="false" (closeEvent)="cancel()">
    <div class="is-flex flex-column">
    <app-filter-input [property]="userFilter"></app-filter-input>
    <ag-grid-angular *ngIf="userFilter.Value.length > 0" class="ag-theme-material email-reports-grid"
    [columnDefs]="colDefs"
    [rowData]="userFilter.Value"
    (gridReady)="onGridReadyAutoSize($event)"
    >
    </ag-grid-angular>
    <button class="button is-primary" (click)="saveAndClose()">Save</button>
        </div>
</app-dialog-container>
