import { Component } from '@angular/core';
import { IHeaderGroupAngularComp } from 'ag-grid-angular';
import { IHeaderGroupParams } from 'ag-grid-community';

@Component({
    selector: 'app-ag-grid-group-header',
    templateUrl: './ag-grid-group-header.component.html',
    styleUrls: ['./ag-grid-group-header.component.scss'],
})
export class AgGridGroupHeaderComponent implements IHeaderGroupAngularComp {
    params: IHeaderGroupParams & {name: string};
    isExpandable = false;
    expandedIcon: string;

    constructor() {
    }

    agInit(params: IHeaderGroupParams & {name: string}): void {
        this.params = params;
        this.isExpandable = params.columnGroup.isExpandable();
        params.columnGroup.getProvidedColumnGroup().addEventListener('expandedChanged', () => this.setExpandedIcon());
        this.setExpandedIcon();
    }

    setExpandedIcon(): void {
        if (this.isExpandable) {
            this.expandedIcon = this.params.columnGroup.isExpanded() ? 'fas fa-fw fa-chevron-left' : 'fas fa-fw fa-chevron-right';
        }
    }

    toggleExpanded(): void {
        if (this.isExpandable) {
            this.params.setExpanded(!this.params.columnGroup.isExpanded());
            this.setExpandedIcon();
        }
    }

}
