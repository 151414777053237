<app-dialog-container (closeEvent)="closeDialog()" [loading]="!filterInit"
                      [title]="title">
    <div class="level is-full-width">
        <app-filter-input *ngFor="let filter of filters" [property]="filter"></app-filter-input>
    </div>
    <div class="level is-full-width">
        <div class="level-right">
            <button class="button level-item is-family-secondary"
                    (click)="closeDialog()"
                    [attr.disabled]="isLoading ? true : null">
                                <span class="icon">
                                    <i *ngIf="!isLoading" class="fas fa-fw fa-times" aria-hidden="true"></i>
                                    <img *ngIf="isLoading" src="assets/images/loader.svg" class="is-size-5"/>
                                </span>
                <span>Cancel</span>
            </button>
            <a class="button level-item is-primary"
                    (click)="validateAndClose()">
                    <span class="icon">
                        <i *ngIf="!isLoading" class="fas fa-fw fa-save has-text-white" aria-hidden="true"></i>
                        <img *ngIf="isLoading" src="assets/images/loader.svg" class="is-size-5"/>
                    </span>
                <span>{{saveLbl}}</span>
            </a>
        </div>
    </div>
</app-dialog-container>
