import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LicenseManager } from 'ag-grid-enterprise';

LicenseManager.setLicenseKey(
    // eslint-disable-next-line max-len
    'CompanyName=Revenue Analytics, Inc.,LicensedGroup=Technology,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=4,LicensedProductionInstancesCount=2,AssetReference=AG-020962,ExpiryDate=22_November_2022_[v2]_MTY2OTA3NTIwMDAwMA==e96ee72bf80dab8ea4cd9b8596cec977',
);

declare global {
    interface Window {
        analytics: {
            track:  (name: string, args: {[key: string]: unknown}, id: {groupId: string}) => void;
            page: (id?: string) => void;
        };
    }
}

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
