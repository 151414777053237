import { Pipe, PipeTransform } from '@angular/core';
import { AutocompleteFilter, Filter } from '@models/filter-types';
import { isAutocompleteFilter } from '@shared/helpers/functions/helpers';

@Pipe({
    name: 'isAutocompleteFilter',
})
export class IsAutocompleteFilterPipe implements PipeTransform {

    transform(value: Filter<unknown>): value is AutocompleteFilter<unknown> {
        return isAutocompleteFilter(value);
    }

}
