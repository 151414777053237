import { Pipe, PipeTransform } from '@angular/core';
import { DateRangeFilter, Filter, FilterTypes } from '@models/filter-types';

@Pipe({
    name: 'isDateRangeFilter',
})
export class IsDateRangeFilterPipe implements PipeTransform {

    transform(value: Filter<unknown>): value is DateRangeFilter {
        return value?.Type === FilterTypes.DateRangeFilterType;
    }

}
