/* eslint-disable prefer-arrow/prefer-arrow-functions*/
/* eslint-disable @typescript-eslint/naming-convention*/
import * as moment from 'moment';
import { Moment } from 'moment';
import { Observable, of } from 'rxjs';
import { DateRange, DateRangeString, LookupItem, StringLookupItem } from './lookup';
import { hasProperty, removeCommasFromValue } from '@shared/helpers/functions/helpers';
import { MatDatepicker, MatDateRangePicker } from '@angular/material/datepicker';
import { MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { UiConfig } from '@models/config';

export enum FilterTypes {
    StringFilterType = 'StringFilterType',
    NumberFilterType = 'NumberFilterType',
    BooleanFilterType = 'BooleanFilterType',
    SelectFilterType = 'SelectFilterType',
    DateFilterType = 'DateFilterType',
    TimeFilterType = 'TimeFilterType',
    DateRangeFilterType = 'DateRangeFilterType',
    MultiSelectFilterType = 'MultiSelectFilterType',
    RadioFilterType = 'RadioFilterType',
    CheckboxFilterType = 'CheckboxFilterType',
    PoliticalWindowConfigTiersFilterType = 'PoliticalWindowConfigTiersFilterType',
    AutocompleteFilterType = 'AutocompleteFilterType',
}

export abstract class Filter<T> {
    validators: { validate: (v: T) => string | boolean; msg: () => string }[] = [];
    isValid = true;
    isRequired = false;
    isColumn = false;
    isReadonly = false;
    validationError = '';
    isWarn = false;
    hint = '';
    hide = false;
    showCreatePoliticalWindow = false;
    hintHtml = '';
    prefix: string;
    suffix: string;
    iconClass: string;
    hasIcon = false;
    displayKey = true;
    inFilterSet = true;
    dynamicFilterParent = false;
    lockValue = false;
    customButton = false;
    customCallback: () => void = null;
    customTitle: string;
    buttonLabel: string;
    showPopupButton: boolean;
    buttonOnClick: () => void;
    buttonTitle: string;
    buttonIsDisabled = false;
    skipFilterRestore = false;
    filterRef: MatInput | MatSelect | MatDatepicker<Moment> | MatDateRangePicker<Moment>;
    isFilterable = false;
    skipValidate = false;

    protected value: T;

    constructor(public Type: FilterTypes, public Key: string, value: T = null) {
        this.value = value;
    }

    public get Value(): T {
        return this.value;
    }

    public set Value(v: T) {
        this.value = v;
        this.onChange(v);
        this.checkAllSelected();
        if (!this.skipValidate) {
            this.Validate();
        }
    }

    public refreshFilter() {
        this.onChange(this.value);
        this.checkAllSelected();
        if (!this.skipValidate) {
            this.Validate();
        }
    }

    transform: (v: T) => unknown = v => v;
    clear: () => void = () => this.Value = null;

    public setValueNoOnChange(v: T) {
        this.value = v;
        this.checkAllSelected();
        if (!this.skipValidate) {
            this.Validate();
        }
    }

    Filterable(isFilterable: boolean): this {
        this.isFilterable = isFilterable;
        return this;
    }

    public Hint(hint: string): this {
        this.hint = hint;
        return this;
    }

    public Prefix(prefix: string): this {
        this.prefix = prefix;
        return this;
    }

    public Suffix(suffix: string): this {
        this.suffix = suffix;
        return this;
    }

    public Icon(iconClass: string): this {
        this.hasIcon = true;
        this.iconClass = iconClass;
        return this;
    }

    public Required(isRequired: boolean): this {
        this.isRequired = isRequired;
        if (this.isRequired) {
            this.Validator(value => value || (typeof value === 'number' && value === 0) ? true : false, () => `${this.Key} is required`);
        } else {
            this.validators = this.validators.filter(validator => !validator.msg().includes('required'));
        }
        return this;
    }

    public Readonly(IsReadonly = true): this {
        this.isReadonly = IsReadonly;
        return this;
    }

    public Default(value: T): this {
        this.Value = value;
        return this;
    }

    public Validator(validator: (v: T) => string | boolean, msg: () => string = () => ''): this {
        this.validators.unshift({ validate: validator, msg });
        return this;
    }

    public Validate(): [boolean, string[]] {
        const errors = [];
        for (const validator of this.validators) {
            const isValid = validator.validate(this.Value);
            if (typeof (isValid) === 'string') {
                this.validationError = isValid;
                errors.push(isValid);
            } else if (!isValid) {
                this.validationError = validator.msg();
                errors.push(validator.msg());
            }
        }

        this.isValid = errors.length === 0;
        this.validationError = this.isValid ? '' : this.validationError;
        return [this.isValid, errors];
    }

    public Transform<K = T>(transform: (v: T) => K | K[]): this {
        this.transform = transform;
        return this;
    }

    public Toggle(hide = true): this {
        this.hide = hide;
        return this;
    }

    public OnChange(callback: (v: T) => void): this {
        this.onChange = callback;
        return this;
    }

    public DisplayKey(displayKey: boolean): this {
        this.displayKey = displayKey;
        return this;
    }

    public SetKey(key: string): this {
        this.Key = key;
        return this;
    }

    public Clear(clear: () => void): this {
        this.clear = clear;
        return this;
    }

    public checkAllSelected(): this {
        return this;
    }

    public IsWarn(isWarn: boolean): this {
        this.isWarn = isWarn;
        return this;
    }

    public InFilterSet(inFilterSet: boolean): this {
        this.inFilterSet = inFilterSet;
        return this;
    }

    public IsDynamicFilterParent(isDynamicFilterParent: boolean): this {
        this.dynamicFilterParent = isDynamicFilterParent;
        return this;
    }

    public ShowPopupButton(
        buttonLabel: string,
        onClick: () => void,
        title: string,
        isDisabled: boolean,
    ): this {
        this.showPopupButton = true;
        this.buttonLabel = buttonLabel;
        this.buttonOnClick = onClick;
        this.buttonTitle = title;
        this.buttonIsDisabled = isDisabled;
        return this;
    }

    public SkipFilterRestore(skipFilterRestore: boolean): this {
        this.skipFilterRestore = skipFilterRestore;
        return this;
    }

    public SkipValidate(skipValidate: boolean): this {
        this.skipValidate = skipValidate;
        this.isValid = true;
        this.validationError = '';
        return this;
    }

    protected onChange: (newValue: T) => void = (v: T) => {
    };
}

export class NumberFilter extends Filter<number> {
    formatString = '1.0-1';
    filterRef: MatInput;

    constructor(Key: string, Value: number = null, Type: FilterTypes = FilterTypes.NumberFilterType) {
        super(Type, Key, Value);
        this.Validator(v => !v || !isNaN(Number(removeCommasFromValue(v))), () => `${this.Key} must be a number`);
    }

    transform: (v: number) => number = v => v !== null ? Number(removeCommasFromValue(v)) : null;

    public Min(min: number, errorMsg?: () => string): this {
        this.Validator(n => +n >= min, errorMsg || (() => `${this.Key} must be >= ${min}`));
        return this;
    }

    public Max(max: number, errorMsg?: () => string): this {
        this.Validator(n => +n <= max, errorMsg || (() => `${this.Key} must be <= ${max}`));
        return this;
    }

    public FormatString(formatString: string): this {
        this.formatString = formatString;
        return this;
    }
}

export class BooleanFilter extends Filter<boolean> {
    isSlider = false;
    disabledTooltip: string = null;

    constructor(Key: string, Value: boolean = false) {
        super(FilterTypes.BooleanFilterType, Key, Value);
    }

    clear: () => void = () => this.Value = false;

    Slider(isSlider: boolean): this {
        this.isSlider = isSlider;
        return this;
    }

    DisabledTooltip(text: string): this {
        this.disabledTooltip = text;
        return this;
    }

}

export class StringFilter extends Filter<string> {
    trimWhitespace = true;
    filterRef: MatInput;

    constructor(Key: string, Value: string = null, Type: FilterTypes = FilterTypes.StringFilterType) {
        super(Type, Key, Value);
        this.Pattern(new RegExp('^[A-Za-z0-9@._%+\'!#$ */=?^`|-]*$'),
                     `${this.Key} can only contain letters, numbers, spaces, and the following symbols: @._%+'!#$ */=?^\`|`);
    }

    transform: (v: string) => string = v => this.trimWhitespace ? v?.trim() : v;


    MinLength(value: number): this {
        this.Validator(v => v && v.length >= value, () => `${this.Key} must be at least ${value} characters`);
        return this;
    }

    MaxLength(value: number): this {
        this.Validator(v => v && v.length <= value, () => `${this.Key} must be at most ${value} characters`);
        return this;
    }

    Pattern(pattern: RegExp, msg: string): this {
        this.Validator(v => pattern.test(v), msg ? () => msg : () => 'The value entered is invalid.');
        return this;
    }

    TrimWhitespace(trimWhitespace: boolean): this {
        this.trimWhitespace = trimWhitespace;
        return this;
    }
}

export class DateFilter extends Filter<Moment> {
    view = 'Day';
    minDate: Moment = null;
    maxDate: Moment = null;
    isEndDate = false;
    filterRef: MatDatepicker<Moment>;
    quickSelectFilter: SelectFilter<LookupItem>;
    showQuickSelectFilter = false;


    constructor(Key: string, Value: Moment = null) {
        super(FilterTypes.DateFilterType, Key, Value);
    }

    dateFilter: (d: Moment) => boolean = () => true;
    resetDate: (d: Moment) => Moment = () => moment(this.Value);

    transform: (v: Moment) => string = v => v ? moment(v).toISOString(true) : undefined;

    Min(date: Moment): this {
        this.minDate = date;
        this.Validator(
            v => !date || !v || moment(v) >= date,
            () => `The date must be on or after ${date.toDate().toDateString()}`,
        );
        return this;
    }

    Max(date: Moment): this {
        this.maxDate = date;
        this.Validator(v => !date || !v || moment(v) <= date, () => `The date must be on or before ${date.toDate().toDateString()}`);
        return this;
    }

    QuickSelectFilter(quickSelectFilter: SelectFilter<LookupItem>): this {
        this.quickSelectFilter = quickSelectFilter;
        return this;
    }

    ShowQuickSelectFilter(showQuickSelectFilter = false): this {
        this.showQuickSelectFilter = showQuickSelectFilter;
        return this;
    }

    Filter(filterFn: (d: Moment) => boolean): this {
        this.dateFilter = filterFn;
        return this;
    }

    View(view: 'Day' | 'Week' | 'Month' | 'Year' | string): this {
        this.view = view;
        return this;
    }

    ResetDate(resetDateFn: (date: Moment) => Moment): this {
        this.resetDate = resetDateFn;
        return this;
    }

    IsEndDate(isEndDate: boolean): this {
        this.isEndDate = isEndDate;
        return this;
    }

    public Open(): void {
        this.filterRef?.open();
    }

    public startAt(startDt: Moment): void{
        this.filterRef.startAt=startDt;
    }
}

export class DateRangeFilter extends Filter<DateRange> {
    view = 'Day';
    minDate: Moment = null;
    maxDate: Moment = null;
    filterRef: MatDateRangePicker<Moment>;

    constructor(Key: string, Value: DateRange = null) {
        super(FilterTypes.DateRangeFilterType, Key, Value);
    }

    dateFilter: (d: Moment) => boolean = () => true;

    transform: (v: DateRange) => DateRangeString = v => v && v.startDate && v.endDate
        ? {
            startDate: v.startDate.toISOString(true),
            endDate: v.endDate.toISOString(true),
        }
        : {
            startDate: undefined,
            endDate: undefined,
        };

    Min(date: Moment): this {
        this.minDate = date;
        this.Validator(
            v => moment(v.startDate) >= date,
            () => `The start date must be on or after ${date.toDate().toDateString()}`,
        );
        return this;
    }

    Max(date: Moment): this {
        this.maxDate = date;
        this.Validator(v => moment(v.endDate) <= date, () => `The end date must be on or before ${date.toDate().toDateString()}`);
        return this;
    }

    Filter(filterFn: (d: Moment) => boolean): this {
        this.dateFilter = filterFn;
        return this;
    }

    public Required(isRequired: boolean, msg?: () => string): this {
        this.isRequired = isRequired;
        if (this.isRequired) {
            this.Validator(value => !!(value.startDate && value.endDate), msg ? msg : () => `${this.Key} is required`);
        } else {
            this.validators = this.validators.filter(validator => !validator.msg().includes('required'));
        }
        return this;
    }

    public Open(): void {
        this.filterRef.open();
    }

}

export class TimeFilter extends Filter<Moment> {
    minTime: Moment = null;
    maxTime: Moment = null;
    step = 30;
    // filterRef: TimePicker;


    constructor(Key: string, Value: Moment = null) {
        super(FilterTypes.TimeFilterType, Key, Value);
    }

    timeFilter: (d: Moment) => boolean = () => true;
    resetDate: (d: Moment) => Moment = () => moment(this.Value);

    transform: (v: Moment) => string = v => v?.format('HH:mm');

    Min(time: Moment): this {
        this.minTime = time;
        this.Validator(
            v => !time || !v || moment(v) >= time,
            () => `The time must be on or after ${time.toDate().toDateString()}`,
        );
        return this;
    }

    Max(time: Moment): this {
        this.maxTime = time;
        this.Validator(v => !time || !v || moment(v) <= time, () => `The time must be on or before ${time.toDate().toDateString()}`);
        return this;
    }

    Step(step: number): this {
        this.step = step;
        return this;
    }

    Filter(filterFn: (d: Moment) => boolean): this {
        this.timeFilter = filterFn;
        return this;
    }

    ResetDate(resetDateFn: (time: Moment) => Moment): this {
        this.resetDate = resetDateFn;
        return this;
    }
}


export class DeferrableFilter<TOptions, TValue = LookupItem> extends Filter<TValue> {
    options: TOptions[] = [];
    filteredOptions: Observable<TOptions[]>;
    triggerText: string;
    isAsync = false;
    isLoading = false;
    isLoaded = false;
    loadError = '';
    isFilterable = false;
    isServerFilter = false;
    triggerLength: number;
    isClearable = false;
    isUnfiltered = true;
    canSelectAll = false;
    isOpened = false;
    name: string;
    restoreWithId = true;
    booleanFilter: BooleanFilter;
    isButtons = false;
    partialButtons = undefined;
    emptySearchText = 'Type to Search';
    defaultName = 'Select';
    filterRef: MatSelect;

    callbackDisplayText = '';
    createPoliticalWindowDisplayText = '';

    public triggerTextFunc: (options: TOptions[], selected: TValue) => string = null;
    protected Loader: () => Observable<TOptions[]> = null;

    public get Value(): TValue {
        return this.value;
    }

    public set Value(v: TValue) {
        this.value = v;
        this.onChange(v);
        this.checkAllSelected();
        this.Validate();
        this.setTriggerText();
    }

    public refreshFilter() {
        this.onChange(this.value);
        this.checkAllSelected();
        this.Validate();
        this.setTriggerText();
    }

    public sort: (items: TOptions[]) => void = (items) => {
    };

    public setValueNoOnChange(v: TValue) {
        this.value = v;
        this.checkAllSelected();
        this.Validate();
        this.setTriggerText();
    }


    compareFn: (lhs: TOptions, rhs: TOptions) => boolean = (l, r) => l === r;

    serverCompareFn: (lhs: TOptions, rhs: TOptions) => boolean = (l, r) =>
        Object.keys(l).filter(key => l[key] === r[key]).length === Object.keys(l).length;

    callbackOption: () => void = () => {
    };

    public filterFn: (filterString: string, options: TOptions[]) => Observable<TOptions[]> =
        (
            filterString,
            options: TOptions[],
        ) => of(options.filter((option: LookupItem) => option?.name.toLowerCase().includes(filterString)));

    Filterable(isFilterable: boolean): this {
        this.isFilterable = isFilterable;
        return this;
    }

    createPoliticalWindow: () => void = () => {
    };

    IsServerFilter(isServerFilter: boolean): this {
        this.isServerFilter = isServerFilter;
        this.isFilterable = true;
        if (isServerFilter) {
            this.CompareFn(this.serverCompareFn);
        }
        return this;
    }

    Defer(loader: () => Observable<TOptions[]>): this {
        this.isAsync = true;
        this.Loader = loader;
        return this;
    }

    Load() {
        this.isLoading = true;
        return this.Loader();
    }

    finishLoad(values: TOptions[]): void {
        this.options = values;
        this.filteredOptions = of(values);
        this.onLoad(this.options);
        this.isLoaded = true;
        this.isLoading = false;

    }

    Options(options: TOptions[]): this {
        this.options = options;
        this.filteredOptions = of(options);
        this.isLoaded = true;
        return this;
    }

    CompareFn(fn: (lhs: TOptions, rhs: TOptions) => boolean): this {
        this.compareFn = fn;
        return this;
    }

    TriggerText(fn: (options: TOptions[], selected: TValue) => string): this {
        this.triggerTextFunc = fn;
        return this;
    }

    TriggerLength(length: number): this {
        this.triggerLength = length;
        return this;
    }

    IsClearable(isClearable: boolean): this {
        this.isClearable = isClearable;
        return this;
    }

    OnLoad(fn: (options: TOptions[]) => void): this {
        this.onLoad = fn;
        return this;
    }

    SetFilterFn(fn: (filterString: string, options: TOptions[]) => Observable<TOptions[]>): this {
        this.filterFn = fn;
        return this;
    }

    Filter(filterString: string, options?: TOptions[]): void {
        this.isUnfiltered = !filterString;
        this.filteredOptions = this.filterFn(filterString, options);
    }

    CallbackOption(displayText: string, callback: () => void): this {
        this.callbackDisplayText = displayText;
        this.callbackOption = callback;
        return this;
    }

    CreatePoliticalWindow(displayText: string, callback: () => void, createPoliticalWindowCapability: boolean = false): this {
        this.createPoliticalWindowDisplayText = displayText;
        this.createPoliticalWindow = callback;
        this.showCreatePoliticalWindow = createPoliticalWindowCapability;
        return this;
    }

    SetName(name: string): this {
        this.name = name;
        return this;
    }

    BooleanFilter(booleanFilter: BooleanFilter): this {
        this.booleanFilter = booleanFilter;
        return this;
    }

    IsButtons(isButtons: boolean): this {
        this.isButtons = isButtons;
        return this;
    }

    PartialButtons(partialButtons: number): this {
        if (partialButtons !== 0) {
            this.partialButtons = partialButtons;
        } else {
            this.partialButtons = undefined;
        }

        return this;
    }

    public AddCustom(customCallback: () => void, customTitle: string = 'Edit Custom', enable: boolean = true): this {
        this.customButton = enable;
        this.customCallback = customCallback;
        this.customTitle = customTitle;
        return this;
    }

    Sort(sortFn: (items: TOptions[]) => void): this {
        this.sort = sortFn;
        return this;
    }

    RestoreWithId(restoreWithId: boolean): this {
        this.restoreWithId = restoreWithId;
        return this;
    }

    EmptySearchText(emptySearchText: string): this {
        this.emptySearchText = emptySearchText;
        return this;
    }

    DefaultName(defaultName: string): this {
        this.defaultName = defaultName;
        return this;
    }

    public Open(): void {
        this.filterRef.open();
    }

    private onLoad: (options: TOptions[]) => void = (o: TOptions[]) => {
    };

    private setTriggerText(): void {
        this.triggerText = this.triggerTextFunc && this.Value
            ? this.triggerTextFunc(this.options, this.Value)
            : '';
    }
}

export class SelectFilter<T> extends DeferrableFilter<T, T> {
    constructor(Key: string, Value: T = null, Type = FilterTypes.SelectFilterType) {
        super(Type, Key, Value);
    }

    clear: () => void = () => {
        if (this.isClearable) {
            this.Value = null;
        } else {
            this.Value = this.options[0];
        }
    };
}

export class MultiSelectFilter<T> extends DeferrableFilter<T, T[]> {
    triggerLength = 2;
    canSelectAll = true;
    isAllSelected = false;
    isSubsetSelected = false;
    restoreObjectReferences = true;
    optionsDeletable = false;

    constructor(Key: string, Value: T[] = []) {
        super(FilterTypes.MultiSelectFilterType, Key, Value);
    }

    clear: () => void = () => this.Value = [];

    triggerTextFunc: (options: T[], selected: T[]) => string =
        (o, s) => o.length === s.length ? 'All ' + this.Key : `${s.length} ` + this.Key + ' Selected';

    public Required(isRequired: boolean, msg?: () => string): this {
        this.isRequired = isRequired;
        if (this.isRequired) {
            this.Validator(value => value && value.length !== 0 ? true : false, msg ? msg : () => `${this.Key} is required`);
        } else {
            this.validators = this.validators.filter(validator => !validator.msg().includes('required'));
        }
        return this;
    }

    SelectAll(select: boolean): void {
        if (!select) {
            this.Value = [];
        } else {
            this.filteredOptions.subscribe(options => this.Value = options);
        }
    }

    SelectAllNoOnChange(select: boolean): void {
        if (!select) {
            this.setValueNoOnChange([]);
        } else {
            this.filteredOptions.subscribe(options => this.setValueNoOnChange(options));
        }
    }

    checkAllSelected(): this {
        if (this.filteredOptions) {
            this.filteredOptions.subscribe(options => {
                this.isAllSelected = options?.length > 0
                    // Either the Value and options objects need to be the same
                    // or the Value and options objects need to have the same length,
                    // and every Value needs to have a corresponding element in the options object
                    && (this.Value === options
                        || (this.Value.every(value =>
                            options.some(option => this.compareFn(value, option)))
                            && options?.length === this.Value.length)
                    );
                this.isSubsetSelected = this.Value?.length > 0 && !this.isAllSelected;
            });
        } else {
            this.isAllSelected = this.options?.length > 0
                // Either the Value and options objects need to be the same
                // or the Value and options objects need to have the same length,
                // and every Value needs to have a corresponding element in the options object
                && (this.Value === this.options
                    || (this.Value.every(value =>
                        this.options.some(option => this.compareFn(value, option))
                        && this.options?.length === this.Value.length))
                );
            this.isSubsetSelected = this.Value?.length > 0 && !this.isAllSelected;
        }
        return this;
    }

    filterByParentValue(
        // TODO: fix typing
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        parent: MultiSelectFilter<any>,
        parentIdField: string,
        allValues: T[],
        selectAll: boolean = false, defaultAll: boolean = true,
    ): void {
        const parentIds = new Set(parent.Value.map(parentItem => parentItem?.id));
        // TODO: fix typing
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const selectedIds = new Set(!this.Value ? [] : this.Value.map((value: any) => value?.id));
        // TODO: fix typing
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.Options(allValues.filter((option: any) => parentIds.has(option[parentIdField])));
        if (selectedIds.size !== 0 && !selectAll) {
            // TODO: fix typing
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const existingParentIds = new Set(!this.Value ? [] : this.Value.map((value: any) => value?.[parentIdField]));
            const newParentIds = [...parentIds].filter(pId => !existingParentIds.has(pId));
            // TODO: fix typing
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.Value = this.options.filter((option: any) => selectedIds.has(option?.id) ||
                newParentIds.includes(option?.[parentIdField]));
        } else if (defaultAll) {
            this.Value = this.options;
        } else {
            this.Value = [];
        }
    }

    RestoreObjectReferences(restoreObjectReferences: boolean): this {
        this.restoreObjectReferences = restoreObjectReferences;
        return this;
    }

    OptionsDeletable(optionsDeletable: boolean): this {
        this.optionsDeletable = optionsDeletable;
        return this;
    }

    public Close(): void {
        this.filterRef?.close();
    }
}

export class RadioFilter<T> extends DeferrableFilter<T, T> {
    constructor(Key: string, Value: T = null) {
        super(FilterTypes.RadioFilterType, Key);
    }
}

export class CheckboxFilter<T> extends DeferrableFilter<T, T[]> {
    constructor(Key: string, Value: T[] = []) {
        super(FilterTypes.CheckboxFilterType, Key, Value);
    }

    clear: () => void = () => this.Value = [];

}

export type FilterTemplate = {
    save?: () => void;
    load?: (_?) => void;
    dynamicFilters?: { [segmentType: string]:
                                            MultiSelectFilter<unknown> |
                                            SelectFilter<unknown> |
                                            StringFilter |
                                            TimeFilter; };
    channelGroup?: SelectFilter<unknown>;
    showMetrics?: boolean;
    tierValue?: unknown;
    primaryRateValue?: unknown;
    alertFilter?: string[];
    changeDates?: boolean;
    savedReportLoaded?: boolean;
    cascadingFilters?: string[];
    config?: UiConfig;
    startDate?: DateFilter;
    endDate?: DateFilter;
};

export class AutocompleteFilter<T = StringLookupItem | LookupItem> extends DeferrableFilter<StringLookupItem
| LookupItem, StringLookupItem | LookupItem | string> {
    placeholder = 'Select';
    constructor(Key: string, Value: T = null, Type = FilterTypes.AutocompleteFilterType) {
        super(Type, Key, Value);
    }

    transform: (v: string | StringLookupItem | LookupItem) => string = v => typeof(v)==='string'?v:(v as LookupItem).name;

    clear: () => void = () => {
        if (this.isClearable) {
            this.Value = null;
        } else {
            this.Value = this.options[0];
        }
    };

    filter: () => void = () => {
        const val = typeof this.Value === 'string' ? this.Value : this.Value.name;
        const filterValue = val.toLowerCase();
        if(filterValue){
            this.filteredOptions = of( this.options.filter(option => option.name.toLowerCase().includes(filterValue)));
        }else{
            this.filteredOptions = of(this.options);
        }
    };

    placeHolder(placeholder: string): this {
        this.placeholder = placeholder;
        return this;
    }
}

export class PoliticalWindowConfigTiersFilter<T> extends SelectFilter<T> {
    showTierGroup = false;
    showPcodes = false;

    constructor(Key: string, Value: T = null) {
        super(Key, Value, FilterTypes.PoliticalWindowConfigTiersFilterType);
    }

    public ShowTierGroup(showTierGroup = false): this {
        this.showTierGroup = showTierGroup;
        return this;
    }

    public ShowPcodes(showPcodes = false): this {
        this.showPcodes = showPcodes;
        return this;
    }
}

export function GetTemplateValueForSaveReport<T = never>(
    template: FilterTemplate,
    excludeFromSelectAll: string[] = [],
    removedFromTemplateFilters: string[] = [],
    isAdvertiserInsights: boolean = false,
): T {
    // TODO: fix typing
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value: any = {};
    for (const k of Object.keys(template)) {
        const isFilter = template[k] instanceof Filter;
        const isMultiSelectFilter = template[k] instanceof MultiSelectFilter;
        const filterIsExcludedFromSelectAll = excludeFromSelectAll.includes(k);
        const includeFilterInTemplate = !removedFromTemplateFilters.includes(k);
        if (isFilter && includeFilterInTemplate) {
            if (isMultiSelectFilter) {
                if (isAdvertiserInsights && filterIsExcludedFromSelectAll) {
                    value[k] = template[k].Value;
                } else {
                    value[k] = template[k].transform(template[k].Value);
                }
            } else {
                value[k] = template[k].transform(template[k].Value);
            }
        }
    }
    if (template.dynamicFilters) {
        for (const k of Object.keys(template.dynamicFilters)) {
            if (template.dynamicFilters[k] instanceof Filter) {
                if (template.dynamicFilters[k] instanceof MultiSelectFilter &&
                    (template.dynamicFilters[k] as MultiSelectFilter<unknown>).isAllSelected &&
                    !excludeFromSelectAll.includes((template.dynamicFilters[k] as MultiSelectFilter<unknown>).name)) {
                    value[k] = 'ALL';
                } else {
                    value[k] = (template.dynamicFilters[k] as MultiSelectFilter<unknown>).transform(
                        (template.dynamicFilters[k] as MultiSelectFilter<unknown>).Value,
                    );
                }
            }
        }
    }

    return value;
}

function hasDynamicFilters(item: object): item is { dynamicFilters?: { [segmentType: string]: MultiSelectFilter<unknown> } } {
    return hasProperty(item, 'dynamicFilters');
}

export function GetTemplateValue<T = never>(template: FilterTemplate | Filter<unknown>[]): T {
    // TODO: fix typing
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value: any = {};

    for (const k of Object.keys(template)) {
        if (template[k] instanceof Filter) {
            value[k] = template[k].transform(template[k].Value);
        }
    }
    if (hasDynamicFilters(template) && template.dynamicFilters) {
        for (const k of Object.keys(template.dynamicFilters)) {
            if (template.dynamicFilters[k] instanceof Filter) {
                value[k] = template.dynamicFilters[k].transform(template.dynamicFilters[k].Value);
            }
        }
    }

    return value;
}

export function GetValue<T = never>(template: FilterTemplate): T {
    // TODO: fix typing
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value: any = {};

    for (const k of Object.keys(template)) {
        if (template[k] instanceof Filter) {
            value[k] = template[k].Value;
        }
    }
    if (template.dynamicFilters) {
        for (const k of Object.keys(template.dynamicFilters)) {
            if (template.dynamicFilters[k] instanceof Filter) {
                value[k] = template.dynamicFilters[k].Value;
            }
        }
    }

    return value;
}
