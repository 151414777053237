import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlStore } from '@shared/helpers/constants/url-store';
import { Observable, of } from 'rxjs';
import { ApiConfig, UiConfig, UiProductConfig } from '@models/config';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    constructor(private http: HttpClient) {
    }

    getConfig(): Observable<UiConfig> {
        return this.http.get<ApiConfig>(UrlStore.api.config).pipe(
            switchMap((apiConfig: ApiConfig) => {
                const userName = apiConfig.userName;
                const userRole = apiConfig.userRole;
                const customerId = apiConfig.customerId;
                const allowedIds = apiConfig.allowedIds;
                const landingPage = apiConfig.landingPage;
                const market = apiConfig.market;
                const station = apiConfig.station;
                const stdRole = apiConfig.stdRole;

                const customerCapability: UiProductConfig = {};
                apiConfig.customerCapability.forEach(product => {
                    customerCapability[product.name] = {};
                    product.modules.forEach(module => {
                        customerCapability[product.name][module.name] = {};
                        module.capabilities.forEach(capability => {
                            customerCapability[product.name][module.name][capability.name] = capability.isEnabled;
                        });
                    });
                });

                const roleCapability: UiProductConfig = {};
                apiConfig.roleCapability.forEach(product => {
                    roleCapability[product.name] = {};
                    product.modules.forEach(module => {
                        roleCapability[product.name][module.name] = {};
                        module.capabilities.forEach(capability => {
                            roleCapability[product.name][module.name][capability.name] = capability.isEnabled;
                        });
                    });
                });
                return of({
                    userName,
                    customerId,
                    allowedIds,
                    userRole,
                    customerCapability,
                    roleCapability,
                    landingPage,
                    market,
                    station,
                    stdRole,
                    channelSegmentation: apiConfig.channelSegmentation,
                    groupSegmentation: apiConfig.groupSegmentation,
                    customerConfig: apiConfig.customerConfig,
                });
            }),
        );
    }

    getZendeskToken(): Observable<{ zendeskJwt: string }> {
        return this.http.get<{ zendeskJwt: string }>(UrlStore.api.zendeskToken);
    }
}
