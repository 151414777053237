import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UrlStore {

    // These must be unique
    // version should be updated whenever the structure of the filter being saved changes
    public static readonly localStorageKeys = {
        dynamicRateCardMetricsTab1: 'DynamicRateCardMetricTab1',
        dynamicRateCardMetricsTab2: 'DynamicRateCardMetricTab2',
        dynamicRateCardMetricsTab3: 'DynamicRateCardMetricTab3',
        dynamicRateCardMetricsTab4: 'DynamicRateCardMetricTab4',
        dynamicRateCardMetricTab1Order: 'DynamicRateCardMetricTab1Order',
        dynamicRateCardMetricTab2Order: 'DynamicRateCardMetricTab2Order',
        dynamicRateCardMetricTab3Order: 'DynamicRateCardMetricTab3Order',
        dynamicRateCardMetricTab4Order: 'DynamicRateCardMetricTab4Order',

        politicalRateCardMetrics: 'PoliticalRateCardMetrics',
        digitalRateCardMetrics: 'DigitalRateCardMetrics',
        politicalRateCardMetricOrder: 'PoliticalRateCardMetricOrder',
        digitalRateCardMetricOrder: 'DigitalRateCardMetricOrder',
        dynamicRateCardSegments: 'DynamicRateCardSegments',
        politicalRateCardSegments: 'PoliticalRateCardSegments',
        digitalRateCardSegments: 'DigitalRateCardSegments',
        dynamicRateCardSegmentsOrder: 'DynamicRateCardSegmentsOrder',
        politicalRateCardSegmentsOrder: 'PoliticalRateCardSegmentsOrder',
        digitalRateCardSegmentsOrder: 'DigitalRateCardSegmentsOrder',
        inventoryMetricsOrder: 'InventoryMetricsOrder',
        inventoryMetrics: 'InventoryMetrics',
        advertiserMetrics: 'AdvertiserMetrics',
        advertiserMetricsOrder: 'AdvertiserMetricsOrder',
        advertiserStationMetrics: 'AdvertiserStationMetrics',
        dashboardMetrics: 'DashboardMetrics',
        dashboardMetricsOrder: 'DashboardMetricsOrder',
        dashboardColumnSortState: 'DashboardColumnSortState',
        rateGeneratorMetrics: 'RateGeneratorMetrics',
        rateGeneratorMetricsOrder: 'RateGeneratorMetricsOrder',
        scenarioUpdateMetrics: 'ScenarioUpdateMetrics',
        scenarioUpdateMetricsOrder: 'ScenarioUpdateMetricsOrder',
        scenarioUpdateMetricsSortState: 'ScenarioUpdateMetricsSortState',
    };

    public static readonly screenNames = {
        rateGenerator: 'rate-generator',
        rateCard: 'rate-card',
        politicalRateCard: 'political-rate-card',
        digitalRateCard: 'digital-rate-card',
        advertiserInsights: 'advertiser-insights',
        politicalLur: 'political-lur',
        inventoryInsights: 'inventory-insights',
        dashboard: 'dashboard',
        scenarioPlanning: 'scenario-planning',
        bulkRates: 'bulk-rates',
        admin: {
            userConfig: 'user-config',
            rateOptics: 'rate-optics',
        },
        aida: 'aida',
    };

    public static readonly rateCardScreens = [
        UrlStore.screenNames.rateCard,
        UrlStore.screenNames.politicalRateCard,
        UrlStore.screenNames.digitalRateCard,
    ];

    public static readonly ui = {
        landing: '/',
        login: 'login',
        loginError: 'login/error',
        loginUserNotConfigured: 'login/userNotConfigured',
        switchCustomer: 'login/switch',
        accessDenied: 'accessDenied',
        documentation: 'documentation',
        admin: {
            base: 'admin',
            rateOpticsAdmin: 'admin/RateOptics',
            rateOpticsUserManagement: 'admin/RateOptics/userManagement',
            rateOpticsSettings: 'admin/RateOptics/settings',
            segmentsManagement: 'admin/SegmentsManagement',
            removeChannels: 'admin/remove-channels',
            updateStatus: 'admin/UpdateStatus',
            roleConfig: 'admin/RoleConfig',
            config: 'admin/Config',
            editWhitelist: 'admin/EditWhitelist',
            userActivityReport: 'admin/UserActivityReport',
            about: 'admin/About',
            emailedReport: 'admin/EmailedReport',
            politicalDayparts: 'admin/PoliticalDayparts',
            aida: 'admin/Aida',
        },
        rateOptics: {
            base: 'RateOptics',
            rateCard: 'RateOptics/DynamicRateCard',
            rateCardV2: 'RateOptics/PricingHub',
            dashboard: 'RateOptics/Dashboard',
            rateGenerator: 'RateOptics/RateGenerator',
            politicalLUR: 'RateOptics/PoliticalLUR',
            advertiserInsights: 'RateOptics/AdvertiserInsights',
            inventoryInsights: 'RateOptics/InventoryInsights',
            ratePerformance: 'RateOptics/RatePerformance',
            bulkRates: 'RateOptics/BulkRates',
            aida: 'RateOptics/Aida',
        },
    };

    public static readonly api = {
        config: `${environment.api}/v1/config`,
        zendeskToken: `${environment.api}/v1/integration/zendesk-token`,
        lookupsV2: {
            period: `${environment.api}/v2/lookups/period`,
            dow: `${environment.api}/v2/lookups/dow`,
            pricingTiers: `${environment.api}/v2/lookups/pricing-tiers`,
            spotTypes: `${environment.api}/v2/lookups/spot-types`,
            newSpotTypes: `${environment.api}/v2/lookups/new-spot-types`,
            categories: `${environment.api}/v2/lookups/categories`,
            products: `${environment.api}/v2/lookups/ratecard/digital-products`,
            productTypes: `${environment.api}/v2/lookups/ratecard/digital-product-types`,
            specialEventTypes: `${environment.api}/v2/lookups/ratecard/event-types`,
            savedReport: `${environment.api}/v2/lookups/saved-report`,
            saveEmailReportFrequency: `${environment.api}/v2/lookups/save-email-report-frequency`,
            saveEmailReportUsers: `${environment.api}/v2/lookups/save-email-report-users`,
            filter: `${environment.api}/v2/lookups/filter`,
            filters: `${environment.api}/v2/lookups/filters`,
            priorityCodes: `${environment.api}/v2/lookups/priority_codes`,
            markets: `${environment.api}/v2/lookups/markets`,
            pCodeMarkets: `${environment.api}/v2/lookups/p-code-markets`,
            stations: `${environment.api}/v2/lookups/stations`,
            pCodeStations: `${environment.api}/v2/lookups/p-code-stations`,
            channels: `${environment.api}/v2/lookups/channels`,
            pCodeChannels: `${environment.api}/v2/lookups/p-code-channels`,
            channelGroups: `${environment.api}/v2/lookups/channel-groups`,
            dayparts: `${environment.api}/v2/lookups/dayparts`,
            asOfDatesV2: `${environment.api}/v2/lookups/dates`,
            rateExpirationDate: `${environment.api}/v2/lookups/rate-expiration-date`,
            customerLogo: `${environment.api}/v2/lookups/customer-logo`,
            autoPublish: `${environment.api}/v2/lookups/auto-publish`,
            rateCardPeriods: `${environment.api}/v2/lookups/rate-card-period`,
            politicalWindow: `${environment.api}/v2/lookups/political-windows`,
            allPoliticalWindow: `${environment.api}/v2/lookups/all-political-windows`,
            politicalInitAllTiers: `${environment.api}/v2/lookups/political-initialization-all-tiers`,
            politicalInitPoliticalTiers: `${environment.api}/v2/lookups/political-initialization-political-tiers`,
            politicalInitTiersHistory: `${environment.api}/v2/lookups/political-initialization-tiers-history`,
            politicalSettings: `${environment.api}/v2/lookups/political-settings`,
            stationExceptions: `${environment.api}/v2/lookups/station-exceptions`,
            digitalPricingTiers: `${environment.api}/v2/lookups/digital-pricing-tiers`,
            dynamicPricingTiers: `${environment.api}/v2/lookups/dynamic-pricing-tiers`,
            quarterOfDate: `${environment.api}/v2/lookups/quarter-of-date`,
            userSavedReportAccess: `${environment.api}/v2/lookups/user-saved-report-access`,
            reportUsers: `${environment.api}/v2/lookups/report-users`,
        },
        ratePerformance: {
            quicksight: `${environment.api}/v2/rate-performance/quicksight-dashboard`,
        },
        userActivity: {
            quicksight: `${environment.api}/v1/user-activity/quicksight-dashboard`,
        },
        rateCardLookupsV2: {
            segments: `${environment.api}/v3/ratecard/segments`,
            addRowPopupSegments: `${environment.api}/v3/ratecard/add-row-segments`,
            politicalSegments: `${environment.api}/v3/ratecard/political/segments`,
            rateCardMetricTypes: `${environment.api}/v2/ratecard/dynamic/metric-types`,
            politicalRateCardMetricTypes: `${environment.api}/v2/ratecard/political/metric-types`,
            digitalRateCardMetricTypes: `${environment.api}/v2/ratecard/digital/metric-types`,
            roundingRules: `${environment.api}/v2/ratecard/rounding-rules`,
            moduleConfig: `${environment.api}/v2/ratecard/module-config`,
            enabledRatecards: `${environment.api}/v2/ratecard/enabled-ratecards`,
            priorityCodeTiers: `${environment.api}/v2/ratecard/priority-code-tiers`,
            priorityCodeStationsQuartersInitialized: `${environment.api}/v2/ratecard/initialized-priority-code-stations-quarters`,
            quarters: `${environment.api}/v2/ratecard/quarters`,
            reorderSegments: `${environment.api}/v2/ratecard/reorder-segments`,
            systemSegments: `${environment.api}/v1/ratecard/system-segment`,
            systemSegmentsAiredWeeks: `${environment.api}/v1/ratecard/segment-aired-weeks`,
            systemSegmentsHiatusWeeks: `${environment.api}/v1/ratecard/segment-hiatus-weeks`,
            dynamicMetricFavorite: `${environment.api}/v2/ratecard/dynamic/favorite-metric`,
            politicalMetricFavorite: `${environment.api}/v2/ratecard/political/favorite-metric`,
            digitalMetricFavorite: `${environment.api}/v2/ratecard/digital/favorite-metric`,
        },
        digital: {
            segments: `${environment.api}/v3/ratecard/digital/segments`,
        },
        customDaypart: {
            customDaypart: `${environment.api}/v1/custom-daypart`,
            customDaypartAll: `${environment.api}/v1/custom-daypart/all`,
            customDaypartAccessForUser: `${environment.api}/v1/custom-daypart/daypart-user-access`,
        },
        advertiserInsightsLookups: {
            advertiserInsightsCascadingFilters: `${environment.api}/v1/advertiser-insights/cascading-filters`,
            advertisers: `${environment.api}/v1/advertiser-insights/advertisers`,
            advertisersCount: `${environment.api}/v1/advertiser-insights/advertisers-count`,
            agencies: `${environment.api}/v1/advertiser-insights/agencies`,
            priorityCodes: `${environment.api}/v1/advertiser-insights/priority-codes`,
            orderCategories: `${environment.api}/v1/advertiser-insights/order-categories`,
            orderDemographics: `${environment.api}/v1/advertiser-insights/order-demographics`,
            orderDetailOptions: `${environment.api}/v1/advertiser-insights/order-detail-options`,
            orderAEs: `${environment.api}/v1/advertiser-insights/account-executives`,
        },
        advertiserInsights: {
            advertiserInsights: `${environment.api}/v1/advertiser-insights`,
            orders: `${environment.api}/v1/advertiser-insights/orders`,
            alerts: `${environment.api}/v1/advertiser-insights/alerts`,
        },
        inventoryInsights: {
            inventoryInsights: `${environment.api}/v1/inventory-insights`,
            inventoryInsightsV2: `${environment.api}/v2/inventory-insights`,
            metrics: `${environment.api}/v1/inventory-insights/metrics`,
            metricsV2: `${environment.api}/v2/inventory-insights/metrics`,
        },
        inventoryInsightsLookups: {
            segments: `${environment.api}/v3/inventory-insights/segments`,
            spotLengths: `${environment.api}/v1/inventory-insights/spot-lengths`,
            spotTypes: `${environment.api}/v1/inventory-insights/spot-types`,
            newSpotTypes: `${environment.api}/v1/inventory-insights/new-spot-types`,
            spotTypesV2: `${environment.api}/v2/inventory-insights/spot-types`,
        },
        specialEventsV2: {
            specialEvents: `${environment.api}/v2/special-events/events`,
            specialEventType: `${environment.api}/v2/special-events/event-type`,
            segments: `${environment.api}/v3/special-events/segments`,
        },
        rateCard: {
            rateCardV2: `${environment.api}/v2/ratecard/dynamic`,
            excelExportData: `${environment.api}/v2/ratecard/excel-export-rate-cards`,
            asyncTriggerLoadRateCardV2: `${environment.api}/v2/ratecard/dynamic-rate-card/async-load`,
            digitalRateCard: `${environment.api}/v2/ratecard/digital`,
            politicalRateCardV2: `${environment.api}/v2/ratecard/political`,
            asyncLoadPoliticalRateCardV2: `${environment.api}/v2/ratecard/political-rate-card/async-load`,
            overridesV2: `${environment.api}/v2/ratecard/dynamic/overrides`,
            politicalOverridesV2: `${environment.api}/v2/ratecard/political/overrides`,
            digitalOverridesV2: `${environment.api}/v2/ratecard/digital/overrides`,
            alerts: `${environment.api}/v1/ratecard/metric-alerts`,
            tierSettings: `${environment.api}/v1/ratecard/pricing-tiers`,
            hiddenSegments: `${environment.api}/v1/ratecard/hidden-segments`,
            userSegment: `${environment.api}/v2/ratecard/user-segment`,
            digitalTierSettings: `${environment.api}/v1/ratecard/digital-pricing-tiers`,
            politicalRateCardSpotLengths: `${environment.api}/v1/ratecard/political-spot-lengths`,
            pCodeTierSettings: `${environment.api}/v1/ratecard/pcode-pricing-tiers`,
            createQuarterlyRateCard: `${environment.api}/v2/ratecard/create-quarterly-rate-card`,
            initializePoliticalRateCard: `${environment.api}/v3/ratecard/initialize-political-rate-card`,
            bulkDRAllocations: `${environment.api}/v1/ratecard/bulk-dr-allocations`,
        },
        politicalLURV2: {
            politicalLUR: `${environment.api}/v2/political-lur`,
            orders: `${environment.api}/v2/political-lur/orders`,
            segments: `${environment.api}/v3/political-lur/segments`,
            spotLengths: `${environment.api}/v2/political-lur/spot-lengths`,

        },
        rateGen: {
            metrics: `${environment.api}/v1/rategen/metrics`,
            otherMetrics: `${environment.api}/v1/rategen/other-metrics`,
            surveyBooks: `${environment.api}/v1/rategen/survey-books`,
            spotLengths: `${environment.api}/v1/rategen/spot-lengths`,
            spotTypes: `${environment.api}/v1/rategen/spot-types`,
        },
        rateGenV2: {
            rateGen: `${environment.api}/v2/rategen`,
            demographics: `${environment.api}/v2/rategen/demographics`,
        },
        woms: {
            uploadV2: `${environment.api}/v2/export/woms/mapping`,
            ratesV2: `${environment.api}/v2/export/woms/rates`,
            programsV2: `${environment.api}/v2/export/woms/programs`,
        },
        admin: {
            metricAlerts: `${environment.api}/v1/admin/metric-alerts`,
            metricAlertsDisplayOrder: `${environment.api}/v1/admin/metric-alerts-display-order`,
            pricingSettings: `${environment.api}/v1/admin/pricing-settings`,
            tierManagement: `${environment.api}/v1/admin/tier-management`,
            tierManagementV2: `${environment.api}/v2/admin/tier-management`,
            priorityCodeMappings: `${environment.api}/v1/admin/priority-code-mappings`,
            politicalTiers: `${environment.api}/v1/admin/political-tiers`,
            quicksight: `${environment.api}/v1/admin/quicksight`,
            politicalWindows: `${environment.api}/v1/admin/political-windows`,
            priorityCodeTiers: `${environment.api}/v1/admin/priority-code-tiers`,
            priorityCodeTiersV2: `${environment.api}/v2/admin/priority-code-tiers`,
            priorityCodeTiersQuarters: `${environment.api}/v1/admin/priority-code-tiers/quarters`,
            politicalWindowsReset: `${environment.api}/v1/admin/political-windows/reset`,
            politicalSettingsUpdate: `${environment.api}/v1/admin/political-mappings`,
            revenueCodes: `${environment.api}/v1/admin/revenue-codes`,
            lurExclusionInclusion: `${environment.api}/v1/admin/lur-exclusion-inclusions`,
            orderType: `${environment.api}/v1/admin/order-type`,
            lurExclusionInclusionAdvertisers: `${environment.api}/v1/admin/lur-exclusion-inclusions/advertisers`,
            lurExclusionInclusionAgencies: `${environment.api}/v1/admin/lur-exclusion-inclusions/agencies`,
        },
        userAdmin: {
            config: `${environment.api}/v1/user-admin/config`,
            appUser: `${environment.api}/v1/user-admin/user`,
            userRoles: `${environment.api}/v1/user-admin/lookups/user-roles`,
            vapid: `${environment.api}/v1/user-admin/vapid`,
            stations: `${environment.api}/v1/user-admin/lookups/stations`,
            channels: `${environment.api}/v1/user-admin/lookups/channels`,
            trafficAEs: `${environment.api}/v1/user-admin/lookups/account-executives`,
            userMarkets: `${environment.api}/v1/user-admin/lookups/user-markets`,
            userStations: `${environment.api}/v1/user-admin/lookups/user-stations`,
            userChannels: `${environment.api}/v1/user-admin/lookups/user-channels`,
            users: `${environment.api}/v1/user-admin/users/adv`,
            userAdvertisersAccess: `${environment.api}/v1/user-admin/user/adv`,
            userAdvertisersNoAccess: `${environment.api}/v1/user-admin/user/adv/unassigned`,
        },
        internalAdmin: {
            roleConfig: `${environment.api}/v2/internal-admin/user-role/config`,
            roleConfigMetrics: `${environment.api}/v2/internal-admin/user-role/metrics`,
            roleConfigOverrideable: `${environment.api}/v2/internal-admin/user-role/metric-overrides`,
            roleConfigMetricsOverrideable: `${environment.api}/v2/internal-admin/user-role/metrics-overrideable-by-role`,
            roleConfigPricingTiers: `${environment.api}/v2/internal-admin/user-role/pricing-tiers`,
            roleConfigDigitalPricingTiers: `${environment.api}/v2/internal-admin/user-role/digital-pricing-tiers`,
            userRole: `${environment.api}/v2/internal-admin/user-role`,
            cloneUserRole: `${environment.api}/v2/internal-admin/user-role/clone`,
            whitelist: `${environment.api}/v2/internal-admin/user-role/whitelist`,
            channelUpdateStatus: `${environment.api}/v2/internal-admin/channel-status/update-status`,
            triggerApp: `${environment.api}/v2/internal-admin/channel-status/trigger-app`,
            triggerAppForNewChannel: `${environment.api}/v2/internal-admin/channel-status/trigger-app-new-channel`,
            refreshAppStatus: `${environment.api}/v2/internal-admin/channel-status/refresh-app`,
            triggerAPPStepFunction: `${environment.api}/v2/internal-admin/channel-status/trigger-app-step-function`,
            deleteChannel: `${environment.api}/v2/internal-admin/channel-status/delete`,
            customerConfig: `${environment.api}/v2/internal-admin/customer-config`,
            customerMetric: `${environment.api}/v2/internal-admin/customer-config/metric`,
            customerRatecardMetric: `${environment.api}/v2/internal-admin/customer-config/ratecard-metric`,
            moduleConfig: `${environment.api}/v2/internal-admin/module-config`,
            channelConfig: `${environment.api}/v2/internal-admin/channel-config`,
            channelGroupConfig: `${environment.api}/v2/internal-admin/channel-group-config`,
            ratecardMetricGroup: `${environment.api}/v2/internal-admin/customer-config/ratecard-metric-group`,
            hiddenSegments: `${environment.api}/v2/internal-admin/segments/hidden`,
            editedSegments: `${environment.api}/v2/internal-admin/segments/edited`,
            addedSegments: `${environment.api}/v2/internal-admin/segments/added`,
            politicalBoundConfig: `${environment.api}/v2/internal-admin/political-bound-config`,
            politicalBoundConfigOptions: `${environment.api}/v2/internal-admin/political-bound-config/political-bound-config-options`,
            emailedReport: `${environment.api}/v2/internal-admin/emailed-report`,
            politicalDayparts: `${environment.api}/v2/internal-admin/political-dayparts`,
        },
        dashboard: {
            dashboardV3: `${environment.api}/v3/dashboard`,
            scenario: `${environment.api}/v3/dashboard/scenario`,
            nationalPlanning: `${environment.api}/v3/dashboard/national-planning`,
            aduPlanning: `${environment.api}/v3/dashboard/adu-planning`,
            drScenarios: `${environment.api}/v3/dashboard/dr-scenarios`,
            impactNetOut: `${environment.api}/v3/dashboard/impact-net-out`,
            scenarioLiability: `${environment.api}/v3/dashboard/scenario-liability`,
            publishScenario: `${environment.api}/v3/dashboard/publish-scenario`,
            quicksight: `${environment.api}/v3/dashboard/quicksight-dashboard`,
        },
        dashboardLookups: {
            aggTypes: `${environment.api}/v1/dashboard/agg-types`,
            monthYears: `${environment.api}/v1/dashboard/month-years`,
            revenueCategories: `${environment.api}/v1/dashboard/revenue-categories`,
            dayparts: `${environment.api}/v1/dashboard/dayparts`,
        },
        specification: {
            specificationV2: `${environment.api}/v2/specification`,
        },
        bulkRates: {
            bulkRates: `${environment.api}/v2/bulk-rates`,
        },
        aida: {
            aida: `${environment.api}/v2/aida`,
            proposalHtml: `${environment.api}/v2/aida/proposal-html`,
            createProposal: `${environment.api}/v2/aida/create-proposal`,
        },
    };


    constructor() {
    }
}
