/* eslint-disable prefer-arrow/prefer-arrow-functions*/

import { forkJoin, Observable, of } from 'rxjs';
import { LookupItem, Period, SegmentItem, StringLookupItem } from '@models/lookup';
import { defaultIfEmpty, switchMap, take } from 'rxjs/operators';
import {
    AutocompleteFilter,
    BooleanFilter,
    CheckboxFilter,
    DateFilter,
    DeferrableFilter,
    Filter,
    FilterTemplate, GetTemplateValue, GetValue,
    MultiSelectFilter,
    SelectFilter,
    StringFilter, TimeFilter,
} from '@models/filter-types';
import { hasProperty, isSegmentItemSelected } from './helpers';
import { Segmentation } from '@models/config';
import * as moment from 'moment';
import { AdvertiserInsightsFilters } from '@models/advertiser-insights/advertiser-insights-filters';
import { endTimeFilter, startTimeFilter } from '@models/base_filters';
import { cloneDeep } from 'lodash';
import { LookupV2Service } from '@services/lookup-v2/lookup-v2.service';

export function getFilters<T>(filters: T): Observable<T> {
    const properties = Object.keys(filters)
        .filter(k => filters[k] instanceof Filter)
        .map(k => filters[k]);

    const defFilterArray = properties.filter(p => p instanceof DeferrableFilter && p.isAsync)
        .map(f => (f as DeferrableFilter<LookupItem, LookupItem>));
    return forkJoin(defFilterArray.map(prop => prop.Load())).pipe(
        switchMap(valueArray => {
            valueArray.forEach((value, index) => {
                defFilterArray[index].finishLoad(value);
            });
            return of(filters);
        }),
    );
}

export function validateFilters(filters: unknown[]): { isValid: boolean; errorList: string[] } {
    let isValid = true;
    let errorList = [];
    filters.forEach(filter => {
        Object.keys(filter)
            .forEach(k => {
                if (filter[k] instanceof Filter) {
                    const validateResponse = filter[k].Validate();
                    isValid = validateResponse[0] && isValid;
                    validateResponse[1].forEach(error => {
                        errorList = error ? errorList.concat((errorList.length === 0 ? '' : '\n') + error) : errorList;
                    });
                } else if (k === 'dynamicFilters') {
                    Object.keys(filter[k])
                        .forEach(dynamicK => {
                            if (filter[k][dynamicK] instanceof Filter) {
                                const validateResponse = filter[k][dynamicK].Validate();
                                isValid = validateResponse[0] && isValid;
                                validateResponse[1].forEach(error => {
                                    errorList = error ? errorList.concat((errorList.length === 0 ? '' : '\n') + error) : errorList;
                                });
                            }
                        });
                }
            });
    });
    return { isValid, errorList };
}

export function generateFilters(
    channelIds: () => number[],
    dayparts: () => number[],
    segments: Segmentation[],
    getSegmentsFunc: (
        channelIds: number[],
        daypartIds: number[],
        segmentation: Segmentation,
        filterValues: { [segmentType: string]: number[] },
        clearCache?: boolean
    ) => Observable<SegmentItem[]>,
): { [segmentType: string]: MultiSelectFilter<SegmentItem> } {
    if (!segments) {
        return {};
    }

    const filters = createFilters(segments);

    segments.forEach(segment => {
        // the set of segments this segment cascades from (what segments are used to filter it)
        const cascadesFrom = segments.filter(s => s.cascades_to.includes(segment.segment_type));
        filters[segment.segment_type]
            .Options([])
            .Defer(() => (
                (cascadesFrom.length > 0 && filters[cascadesFrom[0].segment_type].Value.length > 0)
                || cascadesFrom.length === 0
            ) && channelIds().length > 0
                ? cascadesFrom.length !== 0
                    ? getSegmentsFunc(
                        channelIds(),
                        dayparts(),
                        segment,
                        cascadesFrom.reduce((p, n) => ({
                            ...p,
                            [n.segment_type]: filters[n.segment_type]?.Value.map(i => i.id),
                        }), {}),
                        true,
                    )
                    : getSegmentsFunc(
                        channelIds(),
                        dayparts(),
                        segment,
                        {},
                        true,
                    )
                : of([]))
            .OnLoad(((items: SegmentItem[]) => {
                filters[segment.segment_type].Default(filters[segment.segment_type].Value || items);
            }))
            .CompareFn((l, r) => isSegmentItemSelected(l, r))
            .Filterable(true)
            .OnChange(() => {
                if (filters[segment.segment_type].isOpened) {
                    return;
                }
                const childSegment = segments.find(temp => temp.segment_order === segment.segment_order + 1);
                // the segment we are cascading to
                if (childSegment) {
                    const segsFrom = segments.filter(s => s.cascades_to.includes(childSegment.segment_type));
                    const filterTo = filters[childSegment.segment_type];
                    const cascadeFilters: { [k: string]: number[] } = segsFrom.reduce((p, n) => ({
                        ...p,
                        [n.segment_type]: filters[n.segment_type].Value.map(s => s.id),
                    }), {});

                    filterTo.isLoading = true;
                    if (channelIds().length > 0) {
                        getSegmentsFunc(
                            channelIds(),
                            dayparts(),
                            childSegment,
                            cascadeFilters,
                            false,
                        )
                            .pipe(take(1))
                            .subscribe(items => {
                                cascadeToFilter(filterTo, items);
                            }, err => {
                                console.error(err);
                                filterTo.isLoading = false;
                                filterTo.loadError = 'Failed to Load';
                            });
                    } else {
                        cascadeToFilter(filterTo, []);
                    }
                }
            })
            .Required(true)
            .Toggle(segment.hide_filter ?? false);
    });
    return filters;
}

function createFilters(segments: Segmentation[]): { [segmentType: string]: MultiSelectFilter<SegmentItem> } {
    return segments.reduce((acc, segment) => ({
        ...acc,
        [segment.segment_type]: new MultiSelectFilter(getPluralDynamicSegmentKey(segment.segment_col_header))
            .Icon(segment.icon ?? ''),
    }), {});
}

export function generateSingleSelectFiltersWithSideText(
    channelIds: () => number[],
    dayparts: () => number[],
    segments: Segmentation[],
    getSegmentsFunc: (
        channelIds: number[],
        daypartIds: number[],
        segmentation: Segmentation,
        filterValues: {
            [segmentType: string]: number[];
        },
        clearCache?: boolean
    ) => Observable<SegmentItem[]>,
    defaults: {[segmentType: string]: SegmentItem},
): { [segmentType: string]: SelectFilter<SegmentItem> | StringFilter } {
    if (segments) {
        const filters: { [segmentType: string]: SelectFilter<SegmentItem> } = segments.reduce((p, n) => {
            if (!n.is_start_time && !n.is_end_time) {
                return {
                    ...p,
                    [n.segment_type]: new AutocompleteFilter(n.segment_col_header).Icon(n.icon ?? ''),
                };
            }
            const timeFilters: { startTimeFilter?: TimeFilter; endTimeFilter?: TimeFilter } = {};
            if (n.is_start_time) {
                timeFilters.startTimeFilter = startTimeFilter()
                    .Default(defaults?.[n.segment_type]?.start_time ? moment(defaults?.[n.segment_type]?.start_time as string, 'hh:mm A') : undefined)
                    .OnChange(event => {
                        // this.oneTimeStartTime = event;
                    })
                    .Required(true);
            }

            if (n.is_end_time) {
                timeFilters.endTimeFilter = endTimeFilter()
                    .Default(defaults?.[n.segment_type]?.end_time ? moment(defaults?.[n.segment_type]?.end_time as string, 'hh:mm A') : undefined)
                    .OnChange(event => {
                        // this.oneTimeEndTime = event;
                    })
                    .Required(true);
                timeFilters.startTimeFilter
                    .OnChange(event => {
                        if (!timeFilters.endTimeFilter.Value && event) {
                            const newEndTime = cloneDeep(event);
                            newEndTime.add(1, 'hours');
                            timeFilters.endTimeFilter.Value = newEndTime;
                        }
                    });
            }
            return {
                ...p,
                ...timeFilters,
            };
        }, {});

        segments.forEach(segment => {
            // the set of segments this segment cascades from (what segments are used to filter it)
            if (segment.is_start_time || segment.is_end_time) {
                return;
            } else {
                const cascadesFrom = segments.filter(s => s.cascades_to.includes(segment.segment_type));
                const filterSegmentSelect = (filters[segment.segment_type] as SelectFilter<SegmentItem>);
                filterSegmentSelect
                    .Options([])
                    .Defer(() => (
                        (cascadesFrom.length > 0 && (filters[cascadesFrom[0].segment_type] as SelectFilter<SegmentItem>).Value)
                        || cascadesFrom.length === 0
                    ) && channelIds().length > 0
                        ? cascadesFrom.length !== 0
                            ? getSegmentsFunc(
                                channelIds(),
                                dayparts(),
                                segment,
                                cascadesFrom.reduce((p, n) => ({
                                    ...p,
                                    [n.segment_type]: (filters[segment.segment_type] as SelectFilter<SegmentItem>)?.Value?.id,
                                }), {}),
                                true,
                            )
                            : getSegmentsFunc(
                                channelIds(),
                                dayparts(),
                                segment,
                                {},
                                true,
                            )
                        : of([]))
                    .OnLoad(((items: SegmentItem[]) => {
                        if (defaults?.[segment.segment_type]) {
                            filterSegmentSelect.Value = defaults?.[segment.segment_type];
                        }
                        filterSegmentSelect.Default(filterSegmentSelect.Value);
                    }))
                    .CompareFn((l, r) => isSegmentItemSelected(l, r))
                    .Filterable(true)
                    .OnChange(() => {
                        if (filterSegmentSelect.isOpened) {
                            return;
                        }
                        // Get the non-time segment that's next in order; with removing times/dows it may not be the next +1
                        const childSegment = segments
                            .filter(temp => !(temp.is_end_time || temp.is_start_time) && temp.segment_order > segment.segment_order)
                            .sort((a, b) => a.segment_order - b.segment_order)[0];
                        // the segment we are cascading to
                        if (childSegment) {
                            const segsFrom = segments.filter(s => s.cascades_to.includes(childSegment.segment_type));
                            const filterTo = filters[childSegment.segment_type] as SelectFilter<SegmentItem>;
                            const cascadeFilters: { [k: string]: number[] } = segsFrom.reduce((p, n) => ({
                                ...p,
                                [n.segment_type]: [(filters[n.segment_type] as SelectFilter<SegmentItem>).Value?.id],
                            }), {});
                            filterTo.isLoading = true;
                            if (channelIds().length > 0) {
                                getSegmentsFunc(
                                    channelIds(),
                                    dayparts(),
                                    childSegment,
                                    cascadeFilters,
                                    false,
                                )
                                    .pipe(take(1))
                                    .subscribe(items => {
                                        cascadeToSingleFilter(filterTo, items, defaults?.[childSegment.segment_type], false);
                                    }, err => {
                                        console.error(err);
                                        filterTo.isLoading = false;
                                        filterTo.loadError = 'Failed to Load';
                                    });
                            } else {
                                cascadeToSingleFilter(filterTo, []);
                            }
                        }
                    })
                    .Required(true);
            }
        });
        return filters;
    }
    return {};
}

export function loadSavedValuesIntoFiltersAsync(filters: FilterTemplate, values: unknown) {
    setValuesIntoFilters(filters, values);
    const properties = getPropertiesFromFilters(filters);

    const asyncProperties = properties.filter(p => p instanceof DeferrableFilter && p.isAsync)
        .map(f => (f as DeferrableFilter<unknown, unknown>));
    const nonDeferrableProperties = properties.filter(p => !(p instanceof DeferrableFilter))
        .map(f => (f as Filter<unknown>));

    // load all the deferrable filters and emit the loaded event when they all complete
    return forkJoin(
        asyncProperties.map(prop => prop.Load()),
    ).pipe(switchMap(
        loadResponseList => {
            loadResponseList.forEach((loadResponse, index) => {
                const resp = (!loadResponse || loadResponse.length === 0) &&
                asyncProperties[index].options.length > 0 ? asyncProperties[index].options : loadResponse;
                asyncProperties[index].finishLoad(resp);
            });
            restoreObjectReferences(filters);
            nonDeferrableProperties.forEach(
                // Self assign triggers filter re-render
                // eslint-disable-next-line no-self-assign
                filter => filter.Value = filter.Value,
            );
            return of(loadResponseList);
        },
    ));
}

export function getPropertiesFromFilters(filters: FilterTemplate) {
    let properties = [...Object.keys(filters)
        .filter(k => filters[k] instanceof Filter)
        .map(k => filters[k])];
    if (filters.dynamicFilters) {
        properties = properties.concat(...Object.keys(filters.dynamicFilters)
            .filter(k => filters.dynamicFilters[k] instanceof Filter)
            .map(k => filters.dynamicFilters[k]));
    }
    return properties;
}

export function loadSavedValuesIntoFilters(filters: FilterTemplate, values: unknown) {
    loadSavedValuesIntoFiltersAsync(filters, values).subscribe(() => {
    });
}

export function setValuesIntoFilters(filters: FilterTemplate, values: unknown, resetOptionsToValues: boolean = false) {
    let properties = {};
    let dynamicProperties = {};

    Object.keys(filters)
        .filter(k => filters[k] instanceof Filter)
        .filter(k => !filters[k].skipFilterRestore)
        .map(k => ({ [k]: filters[k] }))
        .forEach(filter => properties = { ...properties, ...filter });
    // TODO: Figure out what these are for and fix/remove them
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    filters.primaryRateValue = values.primaryRateValue;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    filters.alertFilter = values?.alertFilter || filters.alertFilter;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    filters.tierValue = values?.tierValue || filters.tierValue;
    if (filters.dynamicFilters) {
        Object.keys(filters.dynamicFilters)
            .filter(k => filters.dynamicFilters[k] instanceof Filter)
            .filter(k => !filters.dynamicFilters[k].skipFilterRestore)
            .map(k => ({ [k]: filters.dynamicFilters[k] }))
            .forEach(filter => dynamicProperties = { ...dynamicProperties, ...filter });
    }


    const selectFilters = getListOfFilters(properties, SelectFilter);
    const multiSelectFilters = getListOfFilters(properties, MultiSelectFilter);
    const multiSelectFiltersNotAsync = getListOfFilters(properties, MultiSelectFilter, true, true);
    const checkBoxFilters = getListOfFilters(properties, CheckboxFilter);
    const dateFilters = getListOfFilters(properties, DateFilter, false);
    const booleanFilters = getListOfFilters(properties, BooleanFilter, false);

    Object.keys(values).forEach(key => {
        if (selectFilters[key]) {
            setValueForSelectFilters(selectFilters, values, key);
        } else if (multiSelectFilters[key]) {
            setValueForMultiSelectFilters(multiSelectFilters, values, key, resetOptionsToValues, filters);
        } else if (multiSelectFiltersNotAsync[key]) {
            setValueForMultiSelectFilters(multiSelectFiltersNotAsync, values, key, resetOptionsToValues, filters);
        } else if (checkBoxFilters[key]) {
            setValueforCheckBoxFilters(checkBoxFilters, values, key, resetOptionsToValues);
        } else if (dynamicProperties[key]) {
            setValueForDynamicFilters(dynamicProperties, values, key, resetOptionsToValues);
        } else if (dateFilters[key]) {
            setValueForDateFilters(dateFilters, values, key);
        } else if (booleanFilters[key]) {
            setValueForBooleanFilter(booleanFilters, values, key, resetOptionsToValues);
        }
    });

}


export function setValueForSelectFilters(filter, values, key) {
    filter[key].setValueNoOnChange(filter[key].restoreWithId ? { id: values[key] } : values[key]);
}

export function setValueForMultiSelectFilters(filter, values, key, resetOptionsToValues, filters) {
    if (values[key] === 'ALL') {
        filter[key].SelectAllNoOnChange(true);
    } else {
        if (filters instanceof AdvertiserInsightsFilters) {
            if (['stations', 'channels'].includes(key)) {
                filter[key].options = values[key];
            }
        }
        filter[key].setValueNoOnChange(filter[key].restoreWithId ? values[key].map(item => ({ id: item })) : values[key]);
    }
    if (resetOptionsToValues) {
        filter[key].Options(filter[key].Value);
    }
}

export function setValueforCheckBoxFilters(filter, values, key, resetOptionsToValues) {
    filter[key].options.forEach(option => option.included = false);
    values[key].forEach(value => filter[key].options.find(option => option.id === value).included = true);
    if (resetOptionsToValues) {
        filter[key].Options(filter[key].Value);
    }
}

export function setValueForDynamicFilters(filter, values, key, resetOptionsToValues) {
    if (values[key] === 'ALL') {
        filter[key].Value = filter[key].options;
    } else {
        filter[key].setValueNoOnChange(values[key]);
    }
    if (resetOptionsToValues) {
        filter[key].Options(filter[key].Value);
    }
}

export function setValueForDateFilters(filter, values, key) {
    filter[key].setValueNoOnChange(moment(values[key]));
}

export function setValueForBooleanFilter(filter, values, key, resetOptionsToValues) {
    filter[key].setValueNoOnChange(values[key]);
    if (resetOptionsToValues) {
        filter[key].value = values[key];
    }
}

export function getListOfFilters(properties, filterType, useAsync = true, notAsyncList = false) {
    let filterList = {};
    Object.keys(properties)
        .filter(p => {
            const filterVal = properties[p];
            const isFilterTypeInstance = filterVal instanceof filterType;
            const isAsync = filterVal.isAsync;
            if (notAsyncList) {
                return isFilterTypeInstance && !isAsync;
            }
            if (useAsync) {
                return filterVal instanceof filterType && isAsync;
            } else {
                return filterVal instanceof filterType;
            }
        })
        .map(key => ({ [key]: properties[key] }))
        .forEach(filter => filterList = { ...filterList, ...filter });
    return filterList;
}

export function restoreObjectReferences(filters: FilterTemplate) {
    const properties = getPropertiesFromFilters(filters);

    const selectFilterArray = properties.filter(p => p instanceof SelectFilter && p.isAsync)
        .map(f => (f as SelectFilter<LookupItem>));

    const multiSelectFilterArray = properties.filter(p => p instanceof MultiSelectFilter && p.isAsync && p.restoreObjectReferences)
        .map(f => (f as MultiSelectFilter<LookupItem>));

    selectFilterArray.forEach(filter => {
        if(!filter.options[0]?.ids){
            filter.setValueNoOnChange(filter.options.find(option => filter.Value?.id === option.id));
        } else {
            filter.setValueNoOnChange(filter.options.find(option => option.ids.includes(filter.Value?.id)));
        }
    });

    multiSelectFilterArray.forEach((filter: MultiSelectFilter<LookupItem>) => {
        if(!filter.options[0]?.ids) {
            filter.setValueNoOnChange(filter.options.filter(option => filter.Value.map(i => i.id).includes(option.id)));
        } else {
            const selectedIds = [].concat(...filter.Value.map(i => i.ids));
            filter.setValueNoOnChange(filter.options.filter(option => option.ids.some(id => selectedIds.includes(id))));
        }
    });

}

export function cascadeToFilter(
    filter: MultiSelectFilter<LookupItem | StringLookupItem | SegmentItem>,
    newOptions: LookupItem[] | StringLookupItem[] | SegmentItem[],
    defaults?: LookupItem[] | StringLookupItem[] | SegmentItem[],
): boolean {
    // return true if, after the cascade, the filter's values are the same as the filter's options
    // return false if the values are not the same as the new options
    filter.isLoaded = true;
    filter.isLoading = false;
    let subset = true;
    newOptions.forEach(option => {
        subset = subset && filter.options.map(i => i.id).includes(option.id);
    });
    filter.Options(newOptions);
    if (filter.isAllSelected && !subset) {
        filter.Value = filter.options;
        return true;
    } else {

        const newValues = filter.options.filter(item => filter.Value.map(i => i.id).includes(item.id));
        if (newValues.length === 0) {
            if (defaults) {
                filter.Value = defaults;
            } else {
                filter.Value = newOptions;
            }
            return true;
        }
        filter.Value = newValues;
        return filter.Value.length === filter.options.length;

    }
}

export function cascadeToSingleFilter(
    filter: SelectFilter<LookupItem | StringLookupItem | SegmentItem>,
    newOptions: (LookupItem | StringLookupItem| SegmentItem)[],
    defaultVal?: LookupItem | StringLookupItem | SegmentItem,
    autoSelect = true,
): boolean {
    // return true if, after the cascade, the filter's values are the same as the filter's options
    // return false if the values are not the same as the new options
    filter.isLoaded = true;
    filter.isLoading = false;
    const selectedExists = newOptions.find(option => option.id === filter.Value?.id);

    filter.Options(newOptions);
    filter.Value = selectedExists || defaultVal || (autoSelect ? newOptions[0] : null);

    return filter.Value?.id === selectedExists?.id;
}

export function applyFilters<T, K = unknown>(
    template: FilterTemplate,
    templateValueChanged: (filterTemplate: { template: T; value: K }) => void,
): boolean {
    const validatedFilters = validateFilters([template]);
    if (validatedFilters.isValid) {
        templateValueChanged({ template: GetTemplateValue(template), value: GetValue(template) });
        return true;
    } else {
        console.error(validatedFilters.errorList);
        return false;
    }
}

export function updatePeriod(channelGroupId: number, period: SelectFilter<Period>, screenName: string, lookupSvc: LookupV2Service): void {
    period.isLoading = true;
    lookupSvc.getPeriods(screenName, null, channelGroupId)
        .subscribe(result => {
            period.isLoading = false;
            const defaults = result.filter(r => r.isDefault);
            cascadeToSingleFilter(period, result,  defaults[0] || result.find(option => option?.name === 'Week'));
        }, err => {
            console.error(err);
            period.isLoading = false;
            period.loadError = 'Failed to Load';
        });
}


export function resetFilters<T>(
    properties: Filter<unknown>[],
    template: FilterTemplate,
    dynamicProperties: Filter<unknown>[] = [],
    filtersResetValid: (filterTemplate: { template: T; value: T }) => void,
    filterResetInvalid: (filterTemplate: { template: T; value: T }) => void,
) {
    const defPropArray = properties.filter(p => p instanceof DeferrableFilter && p.isAsync)
        .map(f => (f as DeferrableFilter<unknown, unknown>));
    const dynamicDefPropArray = dynamicProperties.filter(p => p instanceof DeferrableFilter && p.isAsync)
        .map(f => (f as DeferrableFilter<unknown, unknown>));
    const nonDefPropArray = properties.filter(p => p instanceof Filter && !(p instanceof DeferrableFilter))
        .map(f => (f as Filter<unknown>));

    nonDefPropArray.forEach(prop => prop.clear());
    defPropArray.forEach(prop => prop.clear());
    dynamicDefPropArray.forEach(prop => prop.clear());


    // load all the deferrable filters and emit the loaded event when they all complete
    forkJoin(
        [forkJoin(defPropArray.map(prop => prop.Load())), forkJoin(dynamicDefPropArray.map(prop => prop.Load())).pipe(defaultIfEmpty([]))],
    ).subscribe(
        (
            [defPropValueArray, dynamicDefPropValueArray],
        ) => {
            defPropValueArray.forEach((value, index) => {
                defPropArray[index].finishLoad(value);
            });
            dynamicDefPropValueArray.forEach((value, index) => {
                dynamicDefPropArray[index].finishLoad(value);
            });
            if (validateFilters([template]).isValid) {

                filtersResetValid({ template: GetTemplateValue(template), value: GetValue(template) });
            } else {
                filterResetInvalid({ template: GetTemplateValue(template), value: GetValue(template) });
            }
        },
    );
}

export function saveFilters(template: FilterTemplate) {
    if (hasProperty(template, 'save')) {
        template.save();
    }
}

export function loadFilters(template: FilterTemplate) {
    if (hasProperty(template, 'load')) {
        template.load();
    }
}

function getPluralDynamicSegmentKey(segmentName: string): string {
    // TODO: This should be moved to a config value in the app at some point
    if(segmentName === 'Day of Week'){
        return 'Days of Week';
    }
    return segmentName + 's';
}
