<mat-form-field [ngClass]="property.isValid ? 'valid' : 'invalid'">
    <mat-label appRemoveAriaOwns>{{property.displayKey ? property.Key : ''}}</mat-label>
    <input nbInput matInput
           type="text"
           [nbTimepicker]="timepicker"
           [(ngModel)]="property.Value"
           (blur)="onBlur()"
    />
    <nb-timepicker #timepicker
                   twelveHoursFormat
                   singleColumn
                   [step]="property.step"
                   (onSelectTime)="onSelect($event)"
                   [timeFormat]="'hh:mm a'"
                   [showFooter]="false"
    ></nb-timepicker>
</mat-form-field>
