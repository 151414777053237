<div class="{{this.data.type}} notification-wrapper">
    <div class="is-pulled-left notification-content">
        <span class="icon is-medium">
            <i class={{this.data.icon}}></i>
        </span>
        <span class="notification-text" [innerHTML]="this.data.message"></span>
    </div>
    <button (click)="dismiss()" matSuffix class="is-pulled-right button notification-close-button {{this.data.type}}">
        <i class="fas fa-times"></i>
    </button>
</div>
