import { Pipe, PipeTransform } from '@angular/core';
import { Filter, SelectFilter } from '@models/filter-types';
import { isSelectFilter } from '@shared/helpers/functions/helpers';

@Pipe({
    name: 'isSelectFilter',
})
export class IsSelectFilterPipe implements PipeTransform {

    transform(value: Filter<unknown>): value is SelectFilter<unknown> {
        return isSelectFilter(value);
    }

}
