import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { HeaderParams } from '@models/ag-grid';

@Component({
    selector: 'app-ag-grid-header',
    templateUrl: './ag-grid-header.component.html',
    styleUrls: ['./ag-grid-header.component.scss'],
})
export class AgGridHeaderComponent implements IHeaderAngularComp {
    params: HeaderParams;
    sortIcon = 'fas fa-fw fa-sort';

    constructor() {
    }

    agInit(params: HeaderParams) {
        this.params = params;

        this.params.api.addEventListener('sortChanged', (event) =>
            this.updateSortIcon(
                event.columnApi.getColumnState().find(col => col.colId === this.params.column.getColId())?.sort,
            ));
        this.updateSortIcon(null);
    }


    sort() {
        if (this.params.enableSorting) {
            this.params.progressSort();
        }
    }

    refresh() {
        return true;
    }

    updateSortIcon(sortDirection: 'asc' | 'desc' | null | undefined) {
        switch (sortDirection) {
        case 'asc':
            this.sortIcon = 'fas fa-fw fa-arrow-up';
            break;
        case 'desc':
            this.sortIcon = 'fas fa-fw fa-arrow-down';
            break;
        default:
            this.sortIcon = 'fas fa-fw fa-sort';
        }
    }

}
