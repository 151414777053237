/*eslint-disable @typescript-eslint/member-ordering */

import {
    FilterTemplate, MultiSelectFilter,
} from '../filter-types';
import { LookupItem, StringLookupItem } from '@models/lookup';
import { of } from 'rxjs';
import * as moment from 'moment';
import { Moment } from 'moment';
import { cascadeToFilter } from '@shared/helpers/functions/filter-helpers';
import { AdvertiserInsightsService } from '@services/advertiser-insights/advertiser-insights.service';
import { switchMap } from 'rxjs/operators';
import { endDateFilter, startDateFilter } from '@models/base_filters';
import { UiConfig } from '@models/config';

const SUNDAY = 0;
const MONDAY = 1;
const NEXT_SUNDAY = 7;

export class AdvertiserInsightsOrdersFilters implements FilterTemplate {
    constructor(
        private advertiserInsightsSvc: AdvertiserInsightsService,
        public config: UiConfig,
        private orders: StringLookupItem[],
        private channels: number[],
    ) {
    }

    flightStart = startDateFilter()
        .SetKey('Flight Start')
        .Filter((date: Moment) => date?.day() === MONDAY);

    flightEnd = endDateFilter()
        .SetKey('Flight End')
        .ResetDate((date: Moment) => {
            if (date && this.flightStart.Value && date < moment(this.flightStart.Value)) {
                date.date(moment(this.flightStart.Value).date());
                date.month(moment(this.flightStart.Value).month());
                date.year(moment(this.flightStart.Value).year());
                date.day(NEXT_SUNDAY);
            }
            return date;
        })
        .Filter((date: Moment) => {
            if (date < moment(this.flightStart.Value)) {
                return false;
            }
            return date?.day() === SUNDAY;
        });

    categories = new MultiSelectFilter<StringLookupItem>('Categories')
        .Defer(() => of([]))
        .Transform(categories => categories.map(category => category.id))
        .Icon('fas fa-box');


    // salesRegions = new MultiSelectFilter<LookupItem>('Sales Region(s)')
    //     .Icon('fas fa-map-marked-alt');
    demographics = new MultiSelectFilter<LookupItem>('Demographic(s)')
        .Defer(() => this.advertiserInsightsSvc.getOrderDemographics(this.orders.map(item => item.id), this.channels)
            .pipe(switchMap((items: LookupItem[]) => {
                this.demographics.Toggle(items.length === 0);
                return of(items);
            })))
        .OnLoad((items: LookupItem[]) => {
            this.demographics.Value = items;
        })
        .OnChange((items: LookupItem[]) => {
            if (!this.demographics.isOpened) {
                this.categories.isLoading = true;
                this.advertiserInsightsSvc.getOrderCategories(this.orders.map(item => item.id), this.channels, items.map(item => item.id))
                    .subscribe((categories: StringLookupItem[]) => {
                        cascadeToFilter(this.categories, categories);
                    },
                               err => {
                                   console.error(err);
                                   this.categories.isLoading = false;
                                   this.categories.loadError = 'Failed to Load';
                               });
            }
        })
        .Transform(demographics => demographics.map(demographic => demographic.id))
        .Toggle(true)
        .Icon('fas fa-id-card');

    accountExecutives = new MultiSelectFilter<LookupItem>('AE(s)')
        .Defer(() => this.advertiserInsightsSvc.getAccountExecutives(this.orders.map(item => item.id)))
        .Toggle(!this.config?.roleCapability.rate_optics.advertiser_insights?.filter_by_ae)
        .Transform(aes => aes.map(ae => ae.id))
        .Icon('fas fa-users');
}
