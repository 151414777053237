import { Pipe, PipeTransform } from '@angular/core';
import { BooleanFilter, Filter, FilterTypes } from '@models/filter-types';

@Pipe({
    name: 'isBooleanFilter',
})
export class IsBooleanFilterPipe implements PipeTransform {

    transform(value: Filter<unknown>): value is BooleanFilter {
        return value?.Type === FilterTypes.BooleanFilterType;
    }

}
