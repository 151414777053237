import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { TimeFilter } from '@models/filter-types';
import { Moment } from 'moment/moment';
import { NbSelectedTimePayload, NbTimePickerComponent, NbTimePickerDirective } from '@nebular/theme';

@Component({
    selector: 'app-time-select',
    templateUrl: './time-select.component.html',
    styleUrls: ['./time-select.component.scss'],
})
export class TimeSelectComponent implements AfterViewInit {

    @Input() property: TimeFilter;
    @Input() icon: string;
    @Input() disabled = true;
    @Input() val: Moment;

    @ViewChild('timepicker') timepicker: NbTimePickerComponent<Moment>;
    @ViewChild(NbTimePickerDirective) timepickerInput: NbTimePickerDirective<Moment>;

    lastChange: Moment;


    constructor() {
    }


    ngAfterViewInit(): void {
        this.timepickerInput.registerOnChange((val) => {
            this.lastChange = val;
            return {};
        });
    }

    onSelect(event: NbSelectedTimePayload<Moment>) {
        if (!event.save) {
            this.timepicker.saveValue();
            this.property.Value = event.time;
            this.timepickerInput.writeValue(this.property.Value);
        }

    }

    onBlur() {
        if(this.lastChange !== undefined){
            this.timepickerInput.writeValue(this.lastChange);
            this.property.Value = this.lastChange;
        }
    }
}
