import { Pipe, PipeTransform } from '@angular/core';
import { Filter, PoliticalWindowConfigTiersFilter } from '@models/filter-types';
import { isPoliticalWindowConfigTiersFilter } from '@shared/helpers/functions/helpers';

@Pipe({
    name: 'isPoliticalWindowsConfigTierFilter',
})
export class IsPoliticalWindowsConfigTierFilterPipe implements PipeTransform {

    transform(value: Filter<unknown>): value is PoliticalWindowConfigTiersFilter<unknown> {
        return isPoliticalWindowConfigTiersFilter(value);
    }

}
