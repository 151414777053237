import { Component, Inject, OnInit } from '@angular/core';
import { LookupV2Service } from '@services/lookup-v2/lookup-v2.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GroupedGridColumn, ReportUser } from '@models/lookup';
import { MultiSelectFilter } from '@models/filter-types';
import { of } from 'rxjs';
import { ColGroupDef, ICellRendererParams } from 'ag-grid-community';
import { getGroupedColumnDefs } from '@shared/helpers/ag-grid/ag-grid-builder';
import { AgGridApiDirective, REMOVE_ROW_COLDEF } from '@shared/helpers/ag-grid/ag-grid';
import { cloneDeep } from 'lodash';

const columns: GroupedGridColumn[] = [
    {
        name: 'Recipients List',
        columns: [
            {
                field: 'first_name',
                name: 'First Name',
                sortable: true,
            },
            {
                field: 'last_name',
                name: 'Last Name',
                sortable: true,
            },
            {
                field: 'email',
                name: 'Email',
                sortable: true,
            },
        ],
    },

];

export interface SelectRecipientsData{
            channels: number[];
            userIds: number[];
        }

@Component({
    selector: 'app-email-reports-select-recipients',
    templateUrl: './email-reports-select-recipients.component.html',
    styleUrls: ['./email-reports-select-recipients.component.scss'],
})
export class EmailReportsSelectRecipientsComponent extends AgGridApiDirective<ReportUser> implements OnInit {

    colDefs: ColGroupDef[];


    userFilter: MultiSelectFilter<ReportUser> = new MultiSelectFilter<ReportUser>('Users')
        .Defer(() => of([]))
        .OnChange((items: ReportUser[]) => {
            this.gridApi?.setRowData([]);
            this.gridApi?.setRowData(items);
            this.gridColumnApi?.autoSizeAllColumns();
        })
        .Transform((items: ReportUser[]) => items.map(item => item.id))
        .Filterable(true)
        .Icon('fas fa-user');

    constructor(
        private lookupSvc: LookupV2Service,
        private dialogRef: MatDialogRef<EmailReportsSelectRecipientsComponent>,
        @Inject(MAT_DIALOG_DATA) private data: SelectRecipientsData,
    ) {
        super();
    }

    ngOnInit() {
        this.lookupSvc.getReportUsers(this.data.channels).subscribe(users => {
            this.userFilter.Options(users);
            this.userFilter.Value = users.filter(user => this.data.userIds?.includes(user.id));
        });
        this.colDefs = getGroupedColumnDefs(columns);
        const removeRowColRef = cloneDeep(REMOVE_ROW_COLDEF);
        removeRowColRef.cellRenderer = (params: ICellRendererParams) =>
            this.removeEditCellRenderer(
                params,
                () => this.userFilter.Value = this.userFilter.Value.filter(val => val.id !== params.data.id),
            );

        this.colDefs[0].children.push(removeRowColRef);
    }

    saveAndClose(): void {
        this.dialogRef.close(this.userFilter.Value.map(user => user.id));
    }

    cancel(): void {
        this.dialogRef.close(this.data.userIds);
    }

}
