import { Pipe, PipeTransform } from '@angular/core';
import { CheckboxFilter, Filter, FilterTypes } from '@models/filter-types';

@Pipe({
    name: 'isCheckboxFilter',
})
export class IsCheckboxFilterPipe implements PipeTransform {

    transform(value: Filter<unknown>): value is CheckboxFilter<unknown> {
        return value?.Type === FilterTypes.CheckboxFilterType;
    }

}
