import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UrlStore } from '@shared/helpers/constants/url-store';
import { AuthService } from '@services/auth/auth.service';

@Component({
    selector: 'app-access-denied',
    templateUrl: './access-denied.component.html',
    styleUrls: ['./access-denied.component.scss'],
})
export class AccessDeniedComponent {

    constructor(
        private router: Router,
        private authSvc: AuthService,
    ) {
    }

    goHome(): void {
        this.router.navigate([UrlStore.ui.landing]);
    }

    logout(): void {
        this.authSvc.logout();
    }

}
