import { inject } from '@angular/core';
import { Route, UrlSegment } from '@angular/router';
import { AuthService } from '@services/auth/auth.service';

export const canMatch = (
    route: Route,
    segments: UrlSegment[],
): boolean =>hasMainAccess(segments.map(segment => segment.path).join('/'));

const hasMainAccess = (url: string): boolean => {
    const auth = inject(AuthService);

    const isConfigured = auth.isConfigured();
    if (!isConfigured) {
        auth.beginLogin(url);
        return false;
    }
    return true;
};
