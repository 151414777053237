import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SwPush } from '@angular/service-worker';
import { UrlStore } from '@shared/helpers/constants/url-store';
import { map } from 'rxjs/operators';

interface NotificationAction {
    action: string;
    title: string;
    icon?: string;
}
interface PushNotification {
    title: string;
    body: string;
    icon?: string;
    badge?: string;
    data?: unknown;
    actions?: NotificationAction[];
}
@Injectable({
    providedIn: 'root',
})
export class PushNotificationService {
    // BehaviorSubject is used to emit the latest value to all subscribers
    notification: BehaviorSubject<PushNotification | null> = new BehaviorSubject<PushNotification | null>(null);
    // subscription stores the push notification subscription
    subscription: PushSubscriptionJSON | null = null;

    blockedNotificationsMessage = '<br>Click ' +
        '<a class="link" href="https://ra-media.zendesk.com/hc/en-us/articles/21883035725844-How-do-I-enable-browser-notifications-">here</a> ' +
        'to view instructions for how to enable browser notifications, so that RateOptics can alert you when this process is complete.';

    // VAPID_PUBLIC_KEY stores the public key for web push notifications
    private vapidPublicKey: string;
    constructor(
        private swPush: SwPush,
        private http: HttpClient,
    ) {
    }
    public getNotification(): Observable<PushNotification | null> {
        return this.notification.asObservable();
    }
    public updateSubscription(subscription: PushSubscriptionJSON): void {
        this.subscription = subscription;
    }
    public updateNotification(notification: PushNotification): void {
        this.notification.next(notification);
    }
    public emptyNotification(): void {
        const emptyPushNotification: PushNotification = {
            title: '',
            body: '',
            data: '',
        };
        this.notification.next(emptyPushNotification);
    }
    public async subscribeToNotification() {
        try {
            const sub = await this.requestSubscription();
            if (sub !== null && sub !== undefined) {
                console.log('Subscription successful.');
            }
            if (this.subscription) {
                this.swPush.messages.subscribe((msg: { notification: PushNotification }) => {
                    this.updateNotification(msg.notification);
                });
            }
        } catch (error) {
            if (error.name === 'InvalidStateError' && error.message.includes('A subscription with a different applicationServerKey')) {
                // Unsubscribe and then resubscribe with the new applicationServerKey
                await this.swPush.unsubscribe();
                await this.subscribeToNotification();
            } else if (error.name === 'NotAllowedError') {
                console.log('Browser notifications are currently turned off');
                return;
            } else {
                throw error;
            }
        }
    }

    public async getSubscription(): Promise<PushSubscriptionJSON> {
        if (this.subscriptionIsEmpty()) {
            await this.subscribeToNotification();
        }
        return this.subscription;
    }

    public subscriptionIsEmpty() {
        const subscriptionElements = [this.subscription?.endpoint, this.subscription?.keys.p256dh, this.subscription?.keys.auth];
        return subscriptionElements.includes(undefined) || subscriptionElements.includes(null);
    }

    private async getVapidPublicKey(): Promise<string> {
        const email = localStorage.getItem('email');
        if (email === null) {
            return null;
        }
        const url = `${UrlStore.api.userAdmin.vapid}/${email}`;
        return this.http.get<{ public_vapid_key: string }>(url).pipe(
            map(res => res.public_vapid_key),
        ).toPromise();
    }
    private async requestSubscription(): Promise<PushSubscription> {
        try {
            // Retrieve the public VAPID key from the server
            this.vapidPublicKey = await this.getVapidPublicKey();
            if (this.vapidPublicKey === null) {
                return null;
            }
            // Request the subscription and update the subscription on the server
            const sub = await this.swPush.requestSubscription({
                serverPublicKey: this.vapidPublicKey,
            });
            this.updateSubscription(sub.toJSON());
            return sub as PushSubscription;
        } catch (err) {
            if (err.name === 'NotAllowedError') {
                console.log('Browser notifications are currently turned off');
                return;
            } else {
                console.error(err);
                throw err;
            }
        }
    }
}
