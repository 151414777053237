<app-dialog-container [loading]="false" (closeEvent)="closeDialogFalse()">
    <div class="is-flex align-center justify-center break-newline has-text-centered">{{data.text}}</div>
    <div class="level">
        <div *ngIf="data.falseButtonText" class="level-left">
            <div class="level-item">
                <a class="button is-link margin-top-75-em" (click)="closeDialogFalse()">{{data.falseButtonText}}</a>
            </div>
        </div>
        <div class="level-right">
            <div class="level-item">
                <a class="button is-link margin-top-75-em" (click)="closeDialogTrue()">{{data.trueButtonText}}</a>
            </div>
        </div>
    </div>
</app-dialog-container>
