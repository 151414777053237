<app-dialog-container *ngIf="filters" (closeEvent)="closeDialog()"
                      [title]="'Orders'" [loading]="false">
    <div class="ra-primary-blue has-text-centered label-wrapper">
        <label class="has-text-weight-semibold">Orders</label>
    </div>
    <div class="level input-line">
        <div class="level-left">

            <app-filter-input [property]="filters.flightStart"></app-filter-input>
            <app-filter-input [property]="filters.flightEnd"></app-filter-input>
            <app-filter-input [property]="filters.demographics"></app-filter-input>
            <app-filter-input [property]="filters.categories"></app-filter-input>
<!--            <app-filter-input [Property]="filters.salesRegions"></app-filter-input>-->
            <app-filter-input [property]="filters.accountExecutives"></app-filter-input>

            <button class="button is-primary is-small" [disabled]="isFiltering" (click)="applyFilters()">
                <span class="icon">
                    <i *ngIf="!isFiltering" class="fas fa-sync-alt has-text-white" aria-hidden="true"></i>
                    <img *ngIf="isFiltering" src="assets/images/loader.svg" class="is-size-5"/>
                </span>
                <span class="has-text-white">Apply Filters</span>
            </button>
        </div>
    </div>
        <div class="advertiser-insights-orders-wrapper">
        <ag-grid-angular class="ag-theme-material advertiser-insights-orders-grid"
                         [rowData]="rowData"
                         [columnDefs]="colDefs"
                         [domLayout]="'autoHeight'"
                         [suppressColumnVirtualisation]="true"
                         [stopEditingWhenCellsLoseFocus]="true"
                         [suppressContextMenu]="true"
                         [defaultColDef]="defaultColDef"
                         (gridReady)="onGridReadyAutoSize($event)"
                         (gridColumnsChanged)="gridColumnApi?.autoSizeAllColumns()"
                         [overlayNoRowsTemplate]="noRowsOverlay"
        >
        </ag-grid-angular>

    </div>
        <div class="level is-full-width margin-top-75-em">
        <div class="level-left">
        </div>
        <div class="level-right">
            <button class="button level-item is-primary" [disabled]="isFiltering"
                    (click)="save()">
                            <span class="icon">
                                <i *ngIf="!isFiltering" class="fas fa-save has-text-white" aria-hidden="true"></i>
                                <img *ngIf="isFiltering" src="assets/images/loader.svg" class="is-size-5"/>
                            </span>
                            <span class="has-text-white">Get Data</span>
            </button>
            <button class="button level-item is-danger" [disabled]="isFiltering"
                    (click)="closeDialog()">
                            <span class="icon">
                                <i *ngIf="!isFiltering" class="fas fa-times has-text-white" aria-hidden="true"></i>
                                <img *ngIf="isFiltering" src="assets/images/loader.svg" class="is-size-5"/>
                            </span>
                            <span class="has-text-white">Cancel</span>
            </button>
        </div>
    </div>
</app-dialog-container>
