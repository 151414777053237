/*eslint-disable @typescript-eslint/member-ordering */

import {
    DeferrableFilter, Filter,
    FilterTemplate,
    GetTemplateValueForSaveReport,
    MultiSelectFilter,
} from '@models/filter-types';
import {
    BooleanLookupItem,
    ChannelItem, DaypartLookupItem,
    LookupItem,
    MarketItem, PoliticalWindowItem,
    SegmentItem,
    StationItem,
} from '../lookup';
import * as moment from 'moment';
import { Moment } from 'moment';
import { cloneDeep } from 'lodash';
import { take } from 'rxjs/operators';
import {
    dateFormatter, doListsHaveSameElements,
} from '@shared/helpers/functions/helpers';
import { Segmentation, UiConfig } from '@models/config';
import {
    cascadeToFilter,
    generateFilters,
    getFilters,
    updatePeriod,
} from '@shared/helpers/functions/filter-helpers';
import { UrlStore } from '@shared/helpers/constants/url-store';
import { of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '@services/notification/notification.service';
import { LookupV2Service } from '@services/lookup-v2/lookup-v2.service';
import {
    channelGroupFilter,
    channelsFilter,
    daypartsFilter,
    dowsFilter,
    endDateFilter,
    loadReport,
    marketsFilter,
    periodFilter,
    saveReport,
    spotTypesFilter,
    startDateFilter,
    stationsFilter,
} from '@models/base_filters';
import { FilterSetComponent } from '@shared/components/filters/filter-set/filter-set.component';
import { FilterSettings } from '@models/filter';
import { PoliticalWindowFilters } from '@models/admin/political-window-filters';
import { ModalEditPopupComponent } from '@shared/components/modal-edit-popup-component/modal-edit-popup.component';
import { PushNotificationService } from '@services/push-notification/push-notification.service';
import { AdminService } from '@services/admin/admin.service';


const DEFAULT_WEEKS_TO_FILTER = 12;
const SUNDAY = 0;
const MONDAY = 1;

type DateView = 'Day' | 'Week' | 'Month' | 'Year';

export class PoliticalRateCardFiltersV2 implements FilterTemplate {
    public marketItems: MarketItem[] = [];
    public stationItems: StationItem[] = [];
    public channelItems: ChannelItem[] = [];
    public loading: boolean;
    public primaryRateValue?: string;
    public alertFilter?: string[] = [];
    public tierValue?: number[] = [];
    public savedReportLoaded = false;
    public rateCardTabsData?: number = 1;
    public showMetrics: boolean;

    public dynamicFilters: { [segmentType: string]: MultiSelectFilter<SegmentItem> } = {};
    initializedPoliticalWindowNames = [];
    newCreatedPoliticalWindowName = '';
    defaultInitializedPoliticalWindow = false;
    defaultNewCreatedPoliticalWindow = false;


    private defaultStartDate = moment().startOf('day');
    private defaultEndDate = this.defaultStartDate.clone().startOf('day').add(DEFAULT_WEEKS_TO_FILTER, 'weeks');

    private windowMin: Moment;
    private windowMax: Moment;

    constructor(
        private lookupSvc: LookupV2Service,
        public config: UiConfig,
        private matDialog: MatDialog,
        private snackBar: NotificationService,
        private adminSvc: AdminService,
        private pushNotificationSvc: PushNotificationService,
        private filterSet: FilterSetComponent,
        public tabId?: string,
        public afterLoad = () => {
        },
    ) {
    }

    settings: FilterSettings = {
        config: this.config,
        lookupSvc: this.lookupSvc,
        hasPoliticalRates: true,
        screenName: UrlStore.screenNames.politicalRateCard,
    };

    dynamicSettings: FilterSettings = {
        config: this.config,
        lookupSvc: this.lookupSvc,
        hasDynamicRates: true,
    };

    channelGroup = channelGroupFilter(this.settings)
        .OnLoad((items: LookupItem[]) => {
            if (this.channelGroup.Value === null && items.length === 1) {
                this.channelGroup.Default(items[0]);
                this.channelGroup.Toggle(true);
            }
        })
        .OnChange((item: LookupItem) => {
            if (item) {
                if (this.markets) {
                    this.markets.Value = [];
                    const newOptions = this.marketItems.filter(i => i.channelGroups.includes(item.id));
                    this.markets.Options(newOptions);
                    if (this.markets.Value.length === 0 && newOptions.length === 1) {
                        this.markets.Value = newOptions;
                    }
                }
                this.getDynamicFilters(item);
                updatePeriod(item?.id, this.period, this.settings.screenName, this.lookupSvc);
            } else {
                this.dynamicFilters = {};
            }
        });

    markets = marketsFilter(this.dynamicSettings)
        .OnLoad((items: MarketItem[]) => {
            if (this.marketItems.length === 0) {
                this.marketItems = cloneDeep(items);
            }
            if (this.channelGroup.Value) {
                if (this.markets.Value.length === 0 && items.length === 1) {
                    this.markets.Default(items);
                }
            }
        })
        .OnChange((items: MarketItem[]) => {
            // when the market changes we filter the list of stations to match
            if (this.stations && this.channelGroup.Value) {
                const newOptions = this.stationItems.filter(i =>
                    items.map(item => item.id).includes(i.marketId)
                    && i.channelGroups.includes(this.channelGroup.Value.id));
                cascadeToFilter(this.stations, newOptions);
            }
            if (!this.markets.isOpened && this.politicalWindows && items.length) {
                this.politicalWindows.clear();
                this.politicalWindows.isLoading = true;
                this.lookupSvc.getPoliticalWindows(items.map(item => item.id)).pipe(take(1))
                    .subscribe((politicalWindows: PoliticalWindowItem[]) => {
                        // Get only windows that are still open
                        const newWindows = politicalWindows.filter(politicalWindow =>
                            moment(politicalWindow.endDate).isSameOrAfter(moment()));
                        newWindows.map(option => {
                            // Map window start date to the current day if it is in the past
                            if (moment(option.startDate).isBefore(moment())) {
                                option.startDate = moment().format('YYYY-MM-DD');
                            }
                            return option;
                        });
                        const defaultWindows = this.politicalWindows.options.filter(window =>
                            this.initializedPoliticalWindowNames.includes(window.baseName));
                        cascadeToFilter(
                            this.politicalWindows,
                            newWindows,
                            defaultWindows.length ? defaultWindows : newWindows.length ? [newWindows[0]] : [],
                        );
                        if (this.defaultNewCreatedPoliticalWindow) {
                            const newDefault = this.politicalWindows.options.filter(window =>
                                window.name.startsWith(this.newCreatedPoliticalWindowName));
                            if (newDefault.length) {
                                this.politicalWindows.Value = newDefault;
                            }
                            this.defaultNewCreatedPoliticalWindow = false;
                        }
                        this.politicalWindows.isLoading = false;
                    }, err => {
                        console.error(err);
                        this.politicalWindows.isLoading = false;
                        this.politicalWindows.loadError = 'Failed to Load';
                    });
            }
        });

    stations = stationsFilter(this.dynamicSettings)
        // load the list of stations and filter by selected markets
        .OnLoad((items: StationItem[]) => {
            // store the full list of stations as the base for filters
            if (this.stationItems.length === 0) {
                this.stationItems = cloneDeep(items);
            }
            const newOptions = this.stationItems.filter(i =>
                this.markets.Value.map(market => market.id).includes(i.marketId)
                && i.channelGroups.includes(this.channelGroup.Value.id));
            cascadeToFilter(this.stations, newOptions);
        })
        .OnChange((items: StationItem[]) => {
            if (this.channels && this.channelGroup.Value) {
                const newOptions = this.channelItems.filter(i =>
                    items.map(item => item.id).includes(i.stationId)
                    && i.channelGroup === this.channelGroup.Value.id);
                cascadeToFilter(this.channels, newOptions);
            }
        });

    channels = channelsFilter(this.dynamicSettings)
        .OnLoad((items: ChannelItem[]) => {
            if (this.channelItems.length === 0) {
                this.channelItems = cloneDeep(items);
            }
            const newOptions = this.channelItems.filter(i =>
                this.stations.Value.map(station => station.id).includes(i.stationId)
                && i.channelGroup === this.channelGroup.Value.id);
            cascadeToFilter(this.channels, newOptions);
        })
        .OnChange((items: ChannelItem[]) => {
            if (items.length === 0) {
                return;
            }
            this.updateSpotTypes();
        });

    politicalWindows = new MultiSelectFilter<PoliticalWindowItem>('Political Window')
        .Defer(() =>
            this.markets?.Value.length > 0
                ? this.lookupSvc.getPoliticalWindows(this.markets.Value.map(i => i.id)) :
                of([]))
        .OnLoad((items: PoliticalWindowItem[]) => {

            this.politicalWindows.Options(
                items.filter(politicalWindow => moment(politicalWindow.endDate).isSameOrAfter(moment())),
            );
            this.politicalWindows.options.map(option => {
                if (moment(option.startDate).isBefore(moment())) {
                    option.startDate = moment().format('YYYY-MM-DD');
                }
                return option;
            });
            if (!this.savedReportLoaded) {
                this.politicalWindows.clear();
                this.politicalWindows.Value = this.politicalWindows.options
                    .filter(politicalWindow =>
                        this.politicalWindows.Value.map(i => i.baseName).includes(politicalWindow.baseName));
                if (this.politicalWindows.Value.length === 0) {
                    this.politicalWindows.Value = this.politicalWindows.options;
                }
            } else {
                const selectedValues = this.politicalWindows.Value.map(value => value.id);
                const valuesToSet: PoliticalWindowItem[] = [];
                this.politicalWindows.options.forEach(option => {
                    selectedValues.forEach(politicalWindowName => {
                        const samePoliticalWindowNames = doListsHaveSameElements(option.baseName, politicalWindowName);
                        if (samePoliticalWindowNames) {
                            valuesToSet.push(option);
                        }
                    });
                });
                this.politicalWindows.Value = valuesToSet;
            }
        })
        .OnChange((items: PoliticalWindowItem[]) => {
            if (items.length === 0) {
                return;
            }
            this.windowMin = moment.min(items.map(item => moment(item.startDate)));
            this.windowMax = moment.max(items.map(item => moment(item.endDate)));
            this.startDate.Value = this.windowMin;
            this.endDate.Value = this.windowMax;
            this.resetDates();
        })
        .Transform((v: PoliticalWindowItem[]) => v.map(window => window.baseName))
        .Icon('fas fa-bullhorn')
        .Filterable(true)
        .Required(true)
        .CreatePoliticalWindow('Create a Political Window', () => this.openCreatePoliticalWindowPopup(), this.settings.config.roleCapability.rate_optics.political_rate_card?.create_political_window_access);

    period = periodFilter(this.settings)
        .OnLoad(items => {
            if (this.period.Value) {
                this.period.Value = items.find(item => item.id === this.period.Value.id);
            } else {
                const defaultValue = this.period.options.find(option => option.isDefault);
                if (defaultValue) {
                    this.period.Default(defaultValue);
                }

            }

        })
        .OnChange(v => {
            // update the views for the date selectors to match the period
            this.startDate.View(v.name as DateView);
            this.endDate.View(v.name as DateView);
            this.resetDates();
            const isReadOnly = v.name === 'Political Window';
            this.endDate.Readonly(isReadOnly);
            this.startDate.Readonly(isReadOnly);
        });

    startDate = startDateFilter()
        .Default(this.defaultStartDate.day(MONDAY))
        .OnChange(_ => {
            this.endDate.Validate();
        })
        .Clear(() => this.startDate.Value = this.defaultStartDate.day(MONDAY))
        .Filter((date: Moment) => {
            const filterDate = date.clone().subtract(7, 'days');
            return !!this.period.Value?.startDateMap?.[filterDate.year()]?.[filterDate.month() + 1]?.[filterDate.date()]
                && this.politicalWindows.Value
                && filterDate.isSameOrAfter(this.windowMin)
                && filterDate.isSameOrBefore(this.windowMax);
        })
        .ResetDate((date: Moment) => {
            this.startDate.Value = date;
            this.resetDates();
            return this.startDate.Value;
        })
        .Validator(_ => !this.isTooManyPeriods(), () => `Please limit your search to ${this.period.Value.maxQueryCount} periods or fewer`);

    endDate = endDateFilter()
        .Default(this.defaultEndDate.day(SUNDAY))
        .OnChange(_ => {
            this.startDate.Validate();
        })
        .Clear(() => this.endDate.Value = this.defaultEndDate.day(SUNDAY))
        .Filter((date: Moment) => {
            const filterDate = date.clone().add(7, 'days');
            return date.isAfter(this.startDate.Value)
                && !!this.period.Value?.endDateMap?.[filterDate.year()]?.[filterDate.month() + 1]?.[filterDate.date()]
                && this.politicalWindows.Value
                && filterDate.isSameOrAfter(this.windowMin)
                && filterDate.isSameOrBefore(this.windowMax);
        })
        .ResetDate((date: Moment) => {
            this.endDate.Value = date;
            this.resetDates();
            return this.endDate.Value;
        })
        .Validator(_ => !this.isTooManyPeriods(), () => `Please limit your search to ${this.period.Value.maxQueryCount} periods or fewer`);

    spotTypes = spotTypesFilter()
        .Defer(() =>
            this.lookupSvc.getSpotTypes(undefined, this.channels.Value.map(i => i.id), this.settings))
        .OnLoad(((items: LookupItem[]) => {
            this.spotTypes.Value = this.spotTypes.options.filter(spotType => this.spotTypes.Value.map(i => i.id).includes(spotType.id));
            this.updateSpotTypes();
        }))
        .OnChange((items: LookupItem[]) => {
            if (items.length === 0) {
                return;
            }
            this.dayparts.isLoading = true;
            const channelIds = this.channels.Value.map(item => item.id);
            const spotTypeIds = this.spotTypes.Value.map(item => item.id);
            this.lookupSvc.getDayparts(channelIds, spotTypeIds, this.settings).pipe(take(1))
                .subscribe((dayparts) => {
                    cascadeToFilter(this.dayparts, dayparts);
                }, err => {
                    console.error(err);
                    this.dayparts.isLoading = false;
                    this.dayparts.loadError = 'Failed to Load';
                });
        });

    dayparts = daypartsFilter(this.settings)
        .Defer(() =>
            this.spotTypes?.Value.length > 0
                ? this.lookupSvc.getDayparts(this.channels.Value.map(i => i.id),
                                             this.spotTypes.Value.map(item => item.id),
                                             this.settings) :
                of([]))
        .OnLoad(((items: DaypartLookupItem[]) => {
            this.dayparts.Value = this.dayparts.options.filter(daypart => this.dayparts.Value.map(i => i.id).includes(daypart.id));
            if (this.dayparts.Value.length === 0) {
                this.dayparts.Value = items;
            }
        }))
        .OnChange((items: LookupItem[]) => {
            if (items.length === 0 || this.channels.Value.length === 0 || this.anyFilterOpen()) {
                return;
            }
            this.getRateCardSegments(items);
        });

    dows = dowsFilter()
        .Defer(() => this.lookupSvc.getDaysOfWeek())
        .OnLoad((items: BooleanLookupItem[]) => {
            items.forEach(item => item.included = item.included ?? true);
        })
        .IsDynamicFilterParent(true)
        .Toggle(true);

    eventType = new MultiSelectFilter<LookupItem>('Special Event Type')
        .Defer(() => this.lookupSvc.getRateCardSpecialEventTypes([], []))
        .OnLoad(_ => {
            if (!(this.eventType.Value.length > 0)) {
                this.eventType.Default([]);
            }
        })
        .Icon('fas fa-gift')
        .Filterable(true)
        .Transform(eventTypes => eventTypes.map(eventType => eventType.id))
        .Toggle(!this.config?.roleCapability?.rate_optics?.dynamic_rate_card?.special_events);

    updateSpotTypes(): void {
        this.spotTypes.isLoading = true;
        this.lookupSvc.getSpotTypes(this.channelGroup.Value?.id, this.channels.Value?.map(
            item => item.id,
        ), this.settings)
            .subscribe(result => {
                this.spotTypes.isLoading = false;
                const defaults = result.filter(r => r.isDefault);
                cascadeToFilter(this.spotTypes, result, defaults);
            }, err => {
                console.error(err);
                this.spotTypes.isLoading = false;
                this.spotTypes.loadError = 'Failed to Load';
            });
    }

    resetDates(): void {
        /* `Finds the start date (or end date) that is immediately on or before the current date,
        so when you change periods up we find the period start date that preceeds the date you have selected.
        i.e. if you moved from week to month today we’d bounce from 10/19/2020 → 9/28/2020` */
        if (this.period.Value?.isPolitical) {
            const startDateValue = this.windowMin;
            if (!this.startDate.Value?.isSame(startDateValue) && startDateValue !== undefined) {
                this.startDate.Value = startDateValue;
            }
            const endDateValue = this.windowMax;
            if (!this.endDate.Value?.isSame(endDateValue) && endDateValue !== undefined) {
                this.endDate.Value = endDateValue;
            }
        } else if (this.period.Value?.startDates) {
            const startDateIndex = this.period.Value.startDates.findIndex(date =>
                date > dateFormatter(this.startDate.Value.toDate(), 'yyyy-MM-dd')) - 1;
            const possibility1 = moment(this.period.Value.startDates[startDateIndex]).diff(moment(this.startDate.Value));
            const possibility2 = moment(this.period.Value.startDates[startDateIndex + 1]).diff(moment(this.startDate.Value));
            const newStartDate = Math.abs(possibility1) > Math.abs(possibility2) ?
                this.period.Value.startDates[startDateIndex + 1] : this.period.Value.startDates[startDateIndex];
            const endDateIndex = this.period.Value.endDates.findIndex(endDate =>
                endDate > dateFormatter(this.endDate.Value.toDate(), 'yyyy-MM-dd')
                && endDate > newStartDate);
            const dateToUse = this.period.Value.name === 'Week' ? this.windowMax : newStartDate;
            const newEndDate =
                this.period.Value?.endDates[endDateIndex - (moment(dateToUse).isAfter(this.endDate.Value) ? 0 : 1)];
            if (newStartDate && !this.startDate.Value.isSame(newStartDate)) {
                this.startDate.Value = moment(newStartDate, 'YYYY-MM-DD');
            }
            if (newEndDate && !this.endDate.Value.isSame(newEndDate)) {
                this.endDate.Value = moment(newEndDate, 'YYYY-MM-DD');
            }
        }

    }

    isTooManyPeriods(): boolean {
        if (this.period.Value?.maxQueryCount) {
            return this.endDate.Value.diff(this.startDate.Value, 'days')
                > this.period.Value.averageDaysPer * this.period.Value.maxQueryCount;
        }
        return false;
    }

    getMetricKey(): string {
        return UrlStore.localStorageKeys.politicalRateCardMetrics;
    }

    getMetricOrderKey(): string {
        return UrlStore.localStorageKeys.politicalRateCardMetricOrder;
    }

    getSegmentsKey(): string {
        return UrlStore.localStorageKeys.politicalRateCardSegments;
    }

    getSegmentsOrderKey(): string {
        return UrlStore.localStorageKeys.politicalRateCardSegmentsOrder;
    }

    excludedFiltersFromselectAll = ['markets', 'stations', 'channels', 'politicalWindows'];
    metricsData = {
        metricsKey: this.getMetricKey(),
        metricsOrderKey: this.getMetricOrderKey(),
    };

    save = () => saveReport(
        this.matDialog,
        UrlStore.screenNames.politicalRateCard,
        this,
        this.lookupSvc,
        this.snackBar,
        this.metricsData,
        this.getSegmentsKey(),
        this.getSegmentsOrderKey(),
        false,
        true,
        GetTemplateValueForSaveReport(this,
                                      this.excludedFiltersFromselectAll,
                                      []),
        this.filterSet.allFiltersSelectedCheck(),
        this.rateCardTabsData,
        false,
    );

    load = () => loadReport(
        this.matDialog,
        UrlStore.screenNames.politicalRateCard,
        this,
        this.lookupSvc,
        this.getMetricKey(),
        this.getMetricOrderKey(),
        this.getSegmentsKey(),
        this.getSegmentsOrderKey(),
        this.afterLoad,
        this.rateCardTabsData,
        this.tabId,
    );

    openCreatePoliticalWindowPopup(): void {

        getFilters(new PoliticalWindowFilters(this.lookupSvc, this.config)).subscribe(filters => {

            filters.startDate.OnChange(() => {
                if (filters.endDate.Value) {
                    filters.endDate.Value = filters.endDate.resetDate(filters.endDate.Value.clone());
                }
                filters.endDate.Open();
            });
            const selectedMarketIds = this.markets.Value.map(i => i.id);
            if (selectedMarketIds.length > 0) {
                filters.markets.Default(filters.markets.options.filter(k => selectedMarketIds.includes(k.id)));
            }
            this.politicalWindows.Close();
            const dialogRef = this.matDialog.open(
                ModalEditPopupComponent,
                {
                    data: {
                        title: 'Create New Political Window',
                        filters: Object.keys(filters)
                            .filter(key => filters[key] instanceof Filter)
                            .map(key => filters[key]),
                        closeFunc: (filterArr: [number[], string, string, string]) =>
                            this.lookupSvc.savePoliticalWindow(
                                filterArr[0],
                                filterArr[1],
                                filterArr[2],
                                filterArr[3],
                            ),
                    },
                },
            );

            const handleDialogClose = async (result: { success: boolean; message: string }) => {
                if (result?.success) {
                    this.snackBar.openSnackBar(
                        'Political Window created successfully. We are setting up the Political Rate Card for the new Window now. ' +
                        'It will be available in the Political Rate Card in a few minutes',
                        'success',
                    );
                    const { markets, startDate, endDate, windowName } = filters;
                    const requestData = {
                        markets: markets.Value.map(market => market.id),
                        startDate: startDate.Value.format('YYYY-MM-DD'),
                        endDate: endDate.Value.format('YYYY-MM-DD'),
                        windowName: windowName.Value,
                        channelGroup: markets.Value[0].channelGroups,
                    };

                    this.pushNotificationSvc.getNotification().subscribe(notification => {
                        if (notification?.title === 'Political Windows Reloaded') {
                            this.snackBar.openSnackBar('Political Window Created Successfully', 'success');
                            this.pushNotificationSvc.emptyNotification();
                        }
                    });
                    this.setFiltersValues(requestData);
                    const subscription = await this.pushNotificationSvc.getSubscription();
                    const email = localStorage.getItem('email');
                    this.adminSvc.resetAndReloadPoliticalWindows(requestData, subscription, email).subscribe(() => {
                        this.afterLoad();
                    });
                }
            };
            dialogRef.afterClosed().subscribe(handleDialogClose);
        });
    }

    getDynamicFilters(channelGroupItem) {
        const groupSegmentation = this.config.groupSegmentation[channelGroupItem.id]?.political_lur || [];
        this.dynamicFilters = generateFilters(
            () => this.channels.Value.map(i => i.id),
            () => this.dayparts.Value.map(daypart => daypart.id),
            groupSegmentation,
            (
                channelIds: number[],
                daypartIds: number[],
                segmentation: Segmentation,
                filterValues: {
                    [segmentType: string]: number[];
                },
                clearCache?: boolean,
            ) => this.lookupSvc.getPoliticalRateCardSegments(channelIds, daypartIds, segmentation, filterValues, clearCache),
        );
    }

    getRateCardSegments(items, clearCache = false) {
        const segmentation = this.config.groupSegmentation[this.channelGroup.Value?.id]?.rate_card;

        if (segmentation?.length) {
            const firstSegmentation = segmentation.sort((first, second) => first.segment_order - second.segment_order)[0];

            const firstFilter = this.dynamicFilters[firstSegmentation.segment_type];
            firstFilter.isLoading = true;
            this.lookupSvc.getPoliticalRateCardSegments(
                this.channels.Value.map(item => item.id),
                items.map(item => item.id),
                firstSegmentation,
                {},
                clearCache,
            )
                .pipe(take(1))
                .subscribe(result => {
                    cascadeToFilter(firstFilter, result);
                    if (clearCache) {
                        //We clear cache after a new user segment is created.
                        this.afterLoad();
                    }

                }, err => {
                    console.error(err);
                    firstFilter.isLoading = false;
                    firstFilter.loadError = 'Failed to Load';
                });
        }
    }

    setFiltersValues(requestData): void {
        this.defaultNewCreatedPoliticalWindow = true;
        this.newCreatedPoliticalWindowName = `${requestData.windowName}, ${requestData.startDate} - ${requestData.endDate}`;
        this.politicalWindows.clear();
        this.channelGroup.Value = this.channelGroup.options.find(option => requestData.channelGroup.includes(option.id));
        this.markets.Value = this.markets.options.filter(market => requestData.markets.includes(market.id));
    }

    anyFilterOpen: () => boolean = () =>
        Object.keys(this)
            .filter(key => this[key] instanceof DeferrableFilter)
            .filter(key => (this[key] as DeferrableFilter<unknown, unknown>).isOpened)
            .length > 0;
}
