import { Route, UrlSegment, Router } from '@angular/router';
import { UrlStore } from '@shared/helpers/constants/url-store';
import { inject } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';


export const canMatch = (
    route: Route,
    segments: UrlSegment[],
): boolean => hasAdminAccess(segments.map(segment => segment.path).join('/'));

const hasAdminAccess = (url: string): boolean => {
    const auth = inject(AuthService);
    const router = inject(Router);
    const isConfigured = auth.isConfigured();
    if (!isConfigured) {
        auth.beginLogin(url);
        return false;

    } else {
        const config = auth.getConfig();
        if (url.includes(UrlStore.ui.admin.base) &&
                config.roleCapability.rate_optics?.admin.access) {
            return true;
        } else if (url.includes(UrlStore.ui.admin.rateOpticsUserManagement) &&
            (config.roleCapability.rate_optics?.user_management.access || auth.isRaUser())) {
            return true;
        } else if (url.includes(UrlStore.ui.admin.rateOpticsSettings) &&
                config.roleCapability.rate_optics?.rate_card.access) {
            return true;
        } else if (url.includes(UrlStore.ui.admin.userActivityReport) &&
                config.roleCapability.rate_optics.user_activity_report.access) {
            return true;
        } else if (
            (
                url.includes(UrlStore.ui.admin.removeChannels)
                    || url.includes(UrlStore.ui.admin.updateStatus)
                    || url.includes(UrlStore.ui.admin.roleConfig)
                    || url.includes(UrlStore.ui.admin.config)
                    || url.includes(UrlStore.ui.admin.segmentsManagement)
                    || url.includes(UrlStore.ui.admin.emailedReport)
                    || url.includes(UrlStore.ui.admin.politicalDayparts)
                    || url.includes(UrlStore.ui.admin.aida)
            ) && ['RA Admin', 'RA Super Admin'].includes(config?.userRole)
        ) {
            return true;
        } else if (
            url.includes(UrlStore.ui.admin.editWhitelist)
                && ['RA Super Admin'].includes(config?.userRole)
        ) {
            return true;
        } else if (
            url.includes(UrlStore.ui.admin.segmentsManagement)
                && (config.roleCapability.rate_optics.dynamic_rate_card.hide_segment
                    || config.roleCapability.rate_optics.dynamic_rate_card.add_segment
                )
        ) {
            return true;
        } else {
            router.navigate([UrlStore.ui.accessDenied]);
            return false;
        }

    }
};
