import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'string',
})
export class StringPipe implements PipeTransform {

    transform(val: number): string {
        if (typeof val === 'number') {
            return String(val);
        } else {
            return val;
        }
    }

}
