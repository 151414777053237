import { Pipe, PipeTransform } from '@angular/core';
import { Filter, FilterTypes, StringFilter } from '@models/filter-types';

@Pipe({
    name: 'isStringFilter',
})
export class IsStringFilterPipe implements PipeTransform {

    transform(value: Filter<unknown>): value is StringFilter {
        return value?.Type === FilterTypes.StringFilterType;
    }

}
