/*eslint-disable @typescript-eslint/member-ordering */
import { FilterTemplate, StringFilter } from '@models/filter-types';
import { endDateFilter, marketsFilter, startDateFilter } from '@models/base_filters';
import { LookupV2Service } from '@services/lookup-v2/lookup-v2.service';
import { UiConfig } from '@models/config';
import { MarketItem } from '@models/lookup';
import { Moment } from 'moment';
import * as moment from 'moment';
import { FilterSettings } from '@models/filter';

const DEFAULT_DAYS_TO_FILTER = 45;
const MAX_WINDOW_MONTHS = 18;

export class PoliticalWindowFilters implements FilterTemplate {
    public defaultStartDate = moment().startOf('day');
    public defaultEndDate = this.defaultStartDate.clone().add(DEFAULT_DAYS_TO_FILTER, 'days');

    constructor(
        private lookupSvc: LookupV2Service,
        public config: UiConfig,
    ) {
    }

    settings: FilterSettings = {
        config: this.config,
        lookupSvc: this.lookupSvc,
    };

    markets = marketsFilter(this.settings)
        .OnLoad((items: MarketItem[]) => {
            this.markets.Value = items;
        });


    startDate = startDateFilter()
        .Default(this.defaultStartDate)
        .OnChange((date: Moment) => {
            if (this.endDate.Value) {
                this.endDate.Value = this.endDate.resetDate(this.endDate.Value.clone());
            }
        })
        .Required(true)
        .Transform(v => v ? moment(v).format('YYYY-MM-DD') : null);

    endDate = endDateFilter()
        .Default(this.defaultEndDate)
        .ResetDate((date: Moment) => {
            if (this.startDate.Value && date < moment(this.startDate.Value)) {
                date.date(moment(this.startDate.Value).date());
                date.month(moment(this.startDate.Value).month());
                date.year(moment(this.startDate.Value).year());
            }
            return date;
        })
        .Filter((date: Moment) => date >= moment(this.startDate.Value))
        .Required(true)
        .Transform(v => v ? moment(v).format('YYYY-MM-DD') : null)
        .Validator(_ => !this.validateWindow(), () => `Please limit your search to 18 months or fewer`);

    windowName = new StringFilter('Window Name')
        .Required(true);

    validateWindow(): boolean {
        return this.endDate.Value.diff(this.startDate.Value, 'months') > MAX_WINDOW_MONTHS;
    }
}
