/* eslint-disable prefer-arrow/prefer-arrow-functions*/
import { formatCurrency, formatDate, formatNumber, formatPercent, getLocaleCurrencySymbol } from '@angular/common';
import * as moment from 'moment';
import { Moment } from 'moment';
import {
    ComparisonOperator,
    HttpParams as HttpParamsInterface,
    IdItem,
    Logo,
    LookupItem,
    NumericLookupItem,
    SegmentItem,
    StringLookupItem,
} from '@models/lookup';
import { Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';
import { Segmentation, UiConfig } from '@models/config';
import { round } from 'lodash';
import { CONFIRM_DIALOG, ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { CellClassParams } from 'ag-grid-community';
import {
    AutocompleteFilter,
    DeferrableFilter,
    Filter,
    FilterTypes,
    MultiSelectFilter,
    PoliticalWindowConfigTiersFilter,
    SelectFilter,
} from '@models/filter-types';
import { NotificationType } from '@services/notification/notification.service';

const LOCALE_ID = 'en-US';
const SUNDAY = 7;
const MONDAY = 1;

export const RA_COLORS = {
    black: '#05053A',
    borderColor: '#bbbbbb',
    fadedBorderColor: '#cccccc',
    lightBackground: '#ffffff',
    darkerBackground: '#e6e6eb',
    darkestBackground: '#f0f0f0',
    raPrimaryBlue: '#101D91',
    raFadedBlue: '#cfd2e9',
    raSemiFadedBlue: '#888ec8',
    raSupportGray: '#44446b',
    raLightestGray: '#e6e6eb',
    raDefaultTextGray: '#44446b',
    raTextWhite: '#ffffff',
    raLightBlue: '#a9c3d5',
    raLightPurple: '#9c90c2',
    raPrimaryRed: '#B70F0A',
    raSemiFadedRed: '#db8785',
    brightRed: '#ff0000',
    raLightOrange: '#f3af88',
    raPrimaryGreen: '#72E2AF',
    raSemiFadedGreen: '#b9f1d7',
    raSecondaryGreen: '#629C44',
    raSecondaryRed: '#B70F0A',
    raPrimaryOrange: '#ff6f2c',
    link: '#101D91',
    primary: '#101D91',
    success: '#72E2AF',
    danger: '#B70F0A',
    warning: '#ffdd57',
    info: '#101D91',
    text: '#05053A',
    overriddenGrey: '#44446b',
    lowerOverrideRed: '#B70F0A',
    higherOverrideGreen: '#629C44',
    selloutLowerLimitBackground: '#DDFFDD',
    selloutUpperLimitBackground: '#FEE8E9',
    raFadedRed: '#F2D9D5',
    raClosedOutRate: '#5C5C7F',
};

export const LAST_DATA_UPDATE = 'Last Data Update:';

export function divide(numerator: number, denominator: number) {
    if (numerator === 0 || denominator === 0) {
        return 0;
    }

    return numerator / denominator;
}

export function currencyFormatter(value: number, digits: string = '1.2-2'): string {
    return formatCurrency(value, LOCALE_ID, getLocaleCurrencySymbol(LOCALE_ID), undefined, digits);
}

export function percentFormatter(value: number, digits: string = '1.0-1'): string {
    return formatPercent(value, LOCALE_ID, digits);
}

export function numberFormatter(value: number, digits: string = '1.0-0'): string {
    return formatNumber(value, LOCALE_ID, digits);
}

export function dateFormatter(value: Date | string | number, format: string = 'shortDate'): string {
    return formatDate(value, format, LOCALE_ID);
}

function isLookupItem(item: LookupItem | NumericLookupItem | StringLookupItem): item is LookupItem {
    return hasProperty(item, 'name');
}

function isStringLookupItem(item: LookupItem | NumericLookupItem | StringLookupItem): item is StringLookupItem {
    return typeof item.id === 'string' && hasProperty(item, 'name');
}

export function isMultiSelectFilter(filter: Filter<unknown>): filter is MultiSelectFilter<unknown> {
    return filter?.Type === FilterTypes.MultiSelectFilterType;
}

export function isSelectFilter(filter: Filter<unknown>): filter is SelectFilter<unknown> {
    return filter?.Type === FilterTypes.SelectFilterType;
}

export function isAutocompleteFilter(filter: Filter<unknown>): filter is AutocompleteFilter<unknown> {
    return filter?.Type === FilterTypes.AutocompleteFilterType;
}

export function isPoliticalWindowConfigTiersFilter(filter: Filter<unknown>): filter is PoliticalWindowConfigTiersFilter<unknown> {
    return filter?.Type === FilterTypes.PoliticalWindowConfigTiersFilterType;
}

export function isDeferrableFilter(filter: Filter<unknown>): filter is DeferrableFilter<unknown> {
    return isSelectFilter(filter) || isMultiSelectFilter(filter) || isPoliticalWindowConfigTiersFilter(filter);
}

export function getDisplayText(item: LookupItem | NumericLookupItem | StringLookupItem | string | number): string {
    if (item || item === '') {
        if (typeof item === 'string' || typeof item === 'number') {
            return String(item);
        }
        if (isStringLookupItem(item)) {
            return item.displayName ?? item.name;
        } else if (isLookupItem(item)) {
            return item.adminDisplayName ?? item.displayName ?? item.name;
        } else {
            return item.displayValue ?? item.value?.toString();
        }
    }
    return null;
}

export function getAllDisplayText(items: (LookupItem | NumericLookupItem | StringLookupItem | string | number)[]): string[] {
    return items.map(item => getDisplayText(item));
}

export function computeFlightWeeks(start: Moment, end: Moment): string[] {
    const startDate = moment(start).day(start.day() >= MONDAY ? MONDAY : MONDAY - 7);
    const lastDay = moment(end).add(SUNDAY - end.isoWeekday(), 'days');
    const items: string[] = [];

    while (startDate < lastDay) {
        items.push(dateFormatter(startDate.toDate(), 'MM/dd/yy'));
        startDate.add(1, 'week');
    }
    return items;
}

export function isMultiOptionSelected<T extends IdItem = LookupItem>(item: T, selectedList: (T)[]): boolean {
    return selectedList.filter(listItem => listItem.id === item.id).length !== 0;
}

export function isSingleOptionSelected(
    item: LookupItem | NumericLookupItem | SegmentItem,
    selected: LookupItem | NumericLookupItem | SegmentItem,
): boolean {
    return item && selected
        ? item.id === selected.id
        : item === selected;
}

export function doListsHaveSameElements(list1, list2) {
    if (!Array.isArray(list1)) {
        list1 = [list1];
    }
    if (!Array.isArray(list2)) {
        list2 = [list2];
    }
    return (
        list1.filter(x => list2.indexOf(x) === -1).length +
        list2.filter(x => list1.indexOf(x) === -1).length
    ) === 0;
}

export function isSegmentItemSelected(item: SegmentItem, selected: SegmentItem): boolean {
    return item && selected
        ? item.id === selected.id
        : item === selected;
}

export function removeCommasFromValue(val: string | number): string {
    if (val) {
        return val.toString().replace(/,/g, '');
    }
    return String(val);
}

export function generateCachedGet<T>(
    clearCache: boolean,
    url: string,
    http: HttpClient,
    params: HttpParamsInterface,
    cache: object,
    useRequestData: boolean = true,
): Observable<T> {

    if (params) {
        if (useRequestData) {
            url = url.concat('?', (new HttpParams().set('requestData', JSON.stringify(params)).toString()));
        } else {
            Object.keys(params).forEach((key, index) => {
                url = url
                    + (index === 0 ? '?' : '&')
                    + key
                    + '='
                    + (Array.isArray(params[key])
                        ? (params[key] as unknown[]).join(',')
                        : params[key]
                    );
            });
        }
    }
    if (clearCache) {
        cache[url] = null;
    }

    if (!cache[url]) {
        cache[url] = http.get<LookupItem[]>(url).pipe(shareReplay(1));
    }
    return cache[url];
}

export function downloadFile(data: string, fileName: string) {
    const blob: Blob = new Blob([data], { type: 'text/xml' });

    const a = document.createElement('a');
    document.body.appendChild(a);

    a.style.display = 'none';

    const url = URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
}

export function exceedsThreshold(value1: string | number, comparator: ComparisonOperator, value2: string | number) {
    switch (comparator) {
    case '<':
        return value1 < value2;
    case '<=':
        return value1 <= value2;
    case '=':
        return value1 === value2;
    case '>=':
        return value1 >= value2;
    case '>':
        return value1 > value2;
    }
    return false;
}

export function appendSegmentationRequestParams(filters: unknown, body: unknown, segmentations?: Segmentation[]): void {
    let channelSegmentation;
    if (segmentations) {
        channelSegmentation = segmentations.map(segmentation => segmentation.segment_type);
    }
    Object.keys(filters).forEach(key => {
        if (channelSegmentation) {
            if (channelSegmentation.includes(key) && filters[key]?.length) {
                body[key] = [].concat(...filters[key].map(item => item.ids));
            }
        } else if (filters[key]?.length && hasProperty(filters[key][0], 'id')) {
            body[key] = [].concat(...filters[key].map(item => item.ids));
        }
    });
}


export function bigMoneyFormatter(input: number, formatString: string = '1.2-2', pipeType: string = 'currency', useFullString: boolean = false): string {
    /* eslint-disable id-blacklist */
    const pipeOptions = {
        number: numberFormatter,
        currency: currencyFormatter,
    };
    /* eslint-enable id-blacklist */
    const suffixes = ['k', 'M', 'B', 'T', 'P', 'E'];
    const fullString = ['Thousand', 'Million', 'Billion', 'Trillion', 'Quadrillion', '?'];

    if (Number.isNaN(input) || input == null) {
        return null;
    }

    if (Math.abs(input) < 1000) {
        return pipeOptions[pipeType](input, formatString);
    }
    const exp = Math.floor(Math.floor((Math.log10(Math.abs(input)))) / Math.log10(1000));

    return pipeOptions[pipeType](input / Math.pow(1000, exp), formatString) + (fullString ? suffixes: fullString)[exp - 1];
}


export function segmentTrack(eventName: string, params: {
    [key: string]: unknown;
}): void {
    const config = JSON.parse(localStorage.getItem('userConfig')) as UiConfig;
    const customerIdDict = { groupId: config?.customerId };
    window.analytics.track(eventName, params, customerIdDict);
}

export function segmentButtonTrack(buttonName: string, params: {
    [key: string]: string;
} = {}): void {
    params.email = localStorage.getItem('email');
    params.button_name = buttonName;
    segmentTrack('User Clicked Button', params);
}

export function segmentPage(): void {
    const config = JSON.parse(localStorage.getItem('userConfig')) as UiConfig;
    window.analytics.page(config?.customerId);
}


export function getBroadcastYearStart(date: Moment) {
    return moment(
        { year: date.year(), month: 0, day: 1 },
    ).startOf('week').add(1, 'day');
}

export function getBroadcastYearEnd(date: Moment) {
    return moment(
        { year: date.year() + 1, month: 0, day: 1 },
    ).startOf('week');
}


export function getLogos(resp: {
    [logoName: string]: Blob;
}): {
    [logoName: string]: Logo;
} {
    const logos: {
        [logoName: string]: Logo;
    } = {};
    Object.keys(resp).forEach(img => {
        const url = resp[img];
        const reader = new FileReader();
        reader.onloadend = () => {
            logos[img] = { image: reader.result as string };
            const htmlImage = document.createElement('img');
            htmlImage.src = reader.result as string;
            htmlImage.onload = () => {
                logos[img].height = htmlImage.height;
                logos[img].width = htmlImage.width;
            };
        };
        reader.readAsDataURL(url);
    });
    return logos;
}

export function roundValue(value: number, rounder: number): number {
    return round(value / rounder) * rounder;
}

export function isClosedOutRate(params: CellClassParams): boolean {
    if (hasProperty(params?.colDef?.cellClassRules, 'closed-out-rate')) {
        return (params.colDef.cellClassRules['closed-out-rate'] as (params: CellClassParams) => boolean)(params);
    }
    return false;
}

export function openDeleteDaypartOptionDialog(option, matDialog, snackBar, lookupSvc, filters): void {
    const confirmDeleteUser = matDialog.open(
        ConfirmDialogComponent, {
            data: {
                text: 'Are you sure you want \n to delete this daypart ?\n' +
                    `${option.name}`,
                autoConfirm: false,
                noClickClose: false,
                confirmText: 'Remove custom daypart',
                denyText: 'Cancel',
            },
        },
    );
    confirmDeleteUser.afterClosed().subscribe(deleteDaypart => {
        if (deleteDaypart === CONFIRM_DIALOG) {
            lookupSvc.deleteCustomDaypartAccessForUser(option.id).subscribe(() => {
                filters.dayparts.options.forEach((daypart, index) => {
                    if (daypart.id === option.id) {
                        filters.dayparts.options.splice(index, 1);
                        filters.dayparts.Options([...filters.dayparts.options]);
                    }
                });
                snackBar.openSnackBar('Deleted custom daypart', 'success');
            }, error => {
                console.error(error);
            });
        }
    });
}

export function nonNullFilterValue(filter) {
    return (filter.Value !== null && filter.Value !== undefined);
}

export function hasProperty(object: object | null | undefined, field: string | number): boolean {
    return Object.hasOwn(object || {}, field);
}

export function handleCustomErrorCode(
    snackBar: (message: string, error: NotificationType) => void,
    error: HttpErrorResponse,
    elseMessage=null,
) {
    // 403 is our custom error code message from the API
    let message;
    if (error?.status === 403) {
        message = error?.error?.message;
    } else {
        message = elseMessage;
    }
    if (message) {
        snackBar(message, 'error');
    }
    console.error(error);
}
