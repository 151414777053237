<mat-form-field [ngClass]="property.isValid ? 'valid' : 'invalid'">
    <span *ngIf="icon" matPrefix><i class="{{ icon }}"></i></span>
    <mat-label appRemoveAriaOwns>{{property.displayKey ? 'Select ' + property.Key : ''}}</mat-label>
    <mat-date-range-input
        [rangePicker]="datePicker"
        [dateFilter]="property.dateFilter"
    >
        <input matStartDate
               [value]="startDate"
               (dateChange)="startDateChanged($event)"
               (click)="datePicker.open()"
        >
        <input matEndDate
               [value]="endDate"
               (dateChange)="endDateChanged($event)"
               (click)="datePicker.open()"
        >

    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-date-range-picker
        #datePicker
        [disabled]="disabled ? disabled : null"
        (closed)="closed()"
    >
    </mat-date-range-picker>
</mat-form-field>
