<mat-form-field
    [ngClass]="[property.isValid ? 'valid' : 'invalid']"
    attr.data-cy="filter-input-autocomplete-{{property.Key}}"
    class="select-filter">
            <span matPrefix *ngIf="(!property.isLoaded || property.isLoading)"><img
                src="assets/images/loader.svg"/></span>
    <span matPrefix class="m-r-5"
          *ngIf="property.hasIcon && ((property.isLoaded && !property.isLoading) || !property.isAsync)">
                <i class="{{property.iconClass}}"></i>
            </span>
    <mat-label
    >{{ property.displayKey ? property.defaultName + ' ' + property.Key : '' }}
    </mat-label>
    <input type="text"
           [placeholder]="property.placeholder"
           matInput
           [disabled]="disabled ? disabled : null"
            [(ngModel)]="property.Value"
            (ngModelChange)="property.filter()"
           [matAutocomplete]="autocompleteFilter"
           aria-label={property.Key}>
    <mat-autocomplete
        [class]="'ag-custom-component-popup autocomplete-overlay'"
        #autocompleteFilter="matAutocomplete"
        [panelWidth]="280"
        id="{{property.Key}}"
        [aria-label]="property.defaultName + ' '  + property.Key"
        (opened)="openedChange()"
        (closed)="closedChange()"
        [displayWith]="displayFn"
    >
        <ng-container *ngIf="((property.filteredOptions | async))?.length">
            <mat-option *ngFor="let option of property.filteredOptions | async" [value]="option">
                {{ option | displayText }}
            </mat-option>
        </ng-container>
    </mat-autocomplete>
    <mat-hint>{{ property.hint }}</mat-hint>
</mat-form-field>
