import { Pipe, PipeTransform } from '@angular/core';
import { LookupItem, NumericLookupItem, StringLookupItem } from '@models/lookup';
import { getDisplayText } from '@shared/helpers/functions/helpers';

type AllowedTypes = LookupItem | NumericLookupItem | string | number | StringLookupItem;
@Pipe({
    name: 'displayText',
})
export class DisplayTextPipe implements PipeTransform {

    transform(item: AllowedTypes | (AllowedTypes)[]): string {
        if (Array.isArray(item)){
            return item.map(x => getDisplayText(x)).join(', ');
        }
        return getDisplayText(item);
    }
}
