<span>
<mat-form-field [ngClass]="property.isValid ? 'valid' : 'invalid'">
    <mat-label appRemoveAriaOwns>{{property.displayKey ? 'Select ' + property.Key : ''}}</mat-label>
    <span *ngIf="icon" matPrefix><i class="{{ icon }}"></i></span>
    <input
        matInput
        [matDatepicker]="datePicker"
        [value]="property.Value"
        [matDatepickerFilter]="property.dateFilter"
        (dateChange)="dateChanged($event)"
        [disabled]="disabled ? disabled : null"
        [readonly]="true"
        (click)="datePicker.open()"
    />
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker
        #datePicker
        startView="{{ startView }}"
        (yearSelected)="chosenYearHandler($event, datePicker)"
        (monthSelected)="chosenMonthHandler($event, datePicker)"
        [disabled]="disabled ? disabled : null"
        (closed)="closed()"
    >
    </mat-datepicker>
</mat-form-field>
    <button *ngIf="property?.quickSelectFilter && property?.showQuickSelectFilter"
       attr.data-cy="filter-input-custom-button-{{property.Key}}"
       (click)="property?.quickSelectFilter.filterRef.open(); $event.stopPropagation()"
       class="button is-small is-primary add-custom-quick-select"
       >
        <span class="margin-right-half-em text-custom">Quick Select</span>
        <i class="fas fa-fw fa-caret-down"></i>
        <app-filter-input
              attr.data-cy="filter-input-select-option-{{property.Key}}"
              [property]="property.quickSelectFilter"
              class="filter-input-custom">
        </app-filter-input>
    </button>
</span>
