import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AdvertiserInsightsFilter, AdvertiserInsightsOrdersFilter } from '@models/filter';
import { AccountExecutive, Grid, GroupedGrid, LookupItem, StringLookupItem } from '@models/lookup';
import { UrlStore } from '@shared/helpers/constants/url-store';
import {
    AdvertiserInsightsAlerts,
    AdvertiserInsightsDependentFiltersRequest, AdvertiserInsightsDependentFiltersResponse, AdvertiserInsightsOrderRow,
    AdvertiserInsightsRow,
} from '@models/advertiser-insights/advertiser-insights';

@Injectable({
    providedIn: 'root',
})
export class AdvertiserInsightsService {

    constructor(private http: HttpClient) {
    }

    loadInsights(
        filter: AdvertiserInsightsFilter,
        advertiserName?: string,
        agencyName?: string,
        categoryName?: string,
    ): Observable<GroupedGrid<AdvertiserInsightsRow>> {
        let body = {
            noChargeOption: filter.noChargeOption.id,
            advertisers: filter.advertisers,
            advertiserName,
            markets: filter.markets,
            stations: filter.stations,
            channels: filter.channels,
            orderDetailOption: filter.orderDetailOption.id,
        };

        if (filter.agencies?.length > 0) {
            body = { ...body, ...{ agencies: filter.agencies }};
            if (agencyName) {
                body = { ...body, ...{ agencyName }};
            }
        }
        if (filter.orderNumbers?.length > 0) {
            body = {
                ...body, ...{ orderNumbers: filter.orderNumbers },
            };
        }
        if (filter.categories?.length > 0) {
            body = { ...body, ...{ categories: filter.categories }};
            if (categoryName) {
                body = { ...body, ...{ categoryName }};
            }
        }
        if (filter.dayparts?.length > 0) {
            body = {
                ...body, ...{ dayparts: filter.dayparts },
            };
        }
        if (filter.spotTypes?.length > 0) {
            body = { ...body, ...{ spotTypes: filter.spotTypes }};
        }
        if (filter.priorityCodes?.length > 0) {
            body = {
                ...body, ...{ priorityCodes: filter.priorityCodes },
            };
        }
        if (filter.spotLengths?.length > 0) {
            body = { ...body, ...{ spotLengths: filter.spotLengths }};
        }
        if (filter.startDate) {
            body = { ...body, ...{ startDate: filter.startDate }};
        }
        if (filter.endDate) {
            body = { ...body, ...{ endDate: filter.endDate }};
        }

        return this.http.post<GroupedGrid<AdvertiserInsightsRow>>(
            UrlStore.api.advertiserInsights.advertiserInsights,
            body,
        );
    }

    getAlerts(
        filter: AdvertiserInsightsFilter,
    ): Observable<AdvertiserInsightsAlerts> {
        let url = UrlStore.api.advertiserInsights.alerts;
        url += `?channels=${filter.channels}`;

        if (filter.dayparts?.length > 0) {
            url += `&dayparts=${filter.dayparts}`;
        }
        if (filter.spotTypes?.length > 0) {
            url += `&spotTypes=${filter.spotTypes}`;
        }
        if (filter.spotLengths?.length > 0) {
            url += `&spotLengths=${filter.spotLengths}`;
        }

        return this.http.get<AdvertiserInsightsAlerts>(url);
    }

    getCascadingFilters(
        filterParams: AdvertiserInsightsDependentFiltersRequest,
    ): Observable<AdvertiserInsightsDependentFiltersResponse> {
        return this.http.post<AdvertiserInsightsDependentFiltersResponse>(
            UrlStore.api.advertiserInsightsLookups.advertiserInsightsCascadingFilters,
            filterParams,
        );
    }

    getPriorityCodes(): Observable<LookupItem[]> {
        return this.http.get<LookupItem[]>(UrlStore.api.advertiserInsightsLookups.priorityCodes);
    }

    getOrders(
        orderNumbers: string[],
        channels: number[],
        filter: AdvertiserInsightsOrdersFilter,
    ): Observable<Grid<AdvertiserInsightsOrderRow>> {
        let body = {};
        if (orderNumbers?.length > 0) {
            body = {
                ...body, ...{ orderNumbers },
            };
        }
        if (channels?.length > 0) {
            body = { ...body, ...{ channelIds: channels }};
        }
        if (filter.categories?.length > 0) {
            body = { ...body, ...{ categories: filter.categories }};
        }
        if (filter.salesRegions?.length > 0) {
            body = { ...body, ...{ salesRegions: filter.salesRegions }};
        }
        if (filter.demographics?.length > 0) {
            body = { ...body, ...{ demographics: filter.demographics }};
        }
        if (filter.accountExecutives?.length > 0) {
            body = { ...body, ...{ accountExecutives: filter.accountExecutives }};
        }
        if (filter.flightStart) {
            body = { ...body, ...{ flightStart: filter.flightStart }};
        }
        if (filter.flightEnd) {
            body = { ...body, ...{ flightEnd: filter.flightEnd }};
        }

        return this.http.post<Grid<AdvertiserInsightsOrderRow>>(
            UrlStore.api.advertiserInsights.orders,
            body,
        );
    }

    getOrderCategories(orderNumbers: string[], channels: number[], demographics: number[]): Observable<StringLookupItem[]> {
        let url = UrlStore.api.advertiserInsightsLookups.orderCategories + `?orderNumbers=${orderNumbers}&channelIds=${channels}`;
        if (demographics.length > 0) {
            url += `&demographics=${demographics}`;
        }
        return this.http.get<StringLookupItem[]>(url);
    }

    getOrderDemographics(orderNumbers: string[], channels: number[]): Observable<LookupItem[]> {
        return this.http.get<LookupItem[]>(
            UrlStore.api.advertiserInsightsLookups.orderDemographics + `?orderNumbers=${orderNumbers}&channelIds=${channels}`,
        );
    }

    getAccountExecutives(orderNumbers: string[]): Observable<AccountExecutive[]> {
        let url = UrlStore.api.advertiserInsightsLookups.orderAEs;
        if (orderNumbers.length > 0) {
            url += `?orderNumbers=${orderNumbers}`;
        }

        return this.http.get<AccountExecutive[]>(url);
    }
}
